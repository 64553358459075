import api from './api';
import { GetInstallmentsProps, GetIof } from './models';

const RESOURCE = 'rest/financeira/cp';

const getBrands = async <T>(): Promise<T> => {
	const response = await api.get<T>(`${RESOURCE}/fipe/marcas`);
	return response.data;
};

const getCarModels = async <T>(shortBrand: string): Promise<T> => {
	const response = await api.get<T>(
		`${RESOURCE}/fipe/modelos?siglaMarca=${shortBrand}`
	);
	return response.data;
};

const getCarYears = async <T>(fipeCode: string): Promise<T> => {
	const response = await api.get<T>(
		`${RESOURCE}/fipe/anos?codigoFipe=${fipeCode}`
	);
	return response.data;
};

const getInstallments = async <T>(body: GetInstallmentsProps): Promise<T> => {
	const response = await api.post<T>(`${RESOURCE}/simulacao/tabela`, body);

	return response.data;
};

const getIof = async <T>(data: GetIof): Promise<T> => {
	const response = await api.post<T>(
		`${RESOURCE}/financiamento/cet/buscar`,
		data
	);

	return response.data;
};

const financialServices = {
	getBrands,
	getCarModels,
	getCarYears,
	getInstallments,
	getIof
};

export default financialServices;
