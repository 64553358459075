import facebookImg from '../../../../assets/icons/facebook.svg';
import linkedinImg from '../../../../assets/icons/linkedin.svg';
import instagramImg from '../../../../assets/icons/instagram.svg';

import * as Styled from './styles';
import { useState } from 'react';

const Footer = () => {
	let content: JSX.Element | null;
	const [fullFooterTextShown, setFullFooterTextShown] = useState(false);

	if (fullFooterTextShown) {
		content = (
			<p>
				Os produtos financeiros da Sinosserra Financeira são comercializados
				através da Sinosserra Promotora de Vendas e Serviços Financeiros Ltda
				(CNPJ 16.789.924/0001-59), que é um correspondente bancário de várias
				instituições do mercado, entre elas: Sinosserra Financeira, Banco GM,
				Banco Volkswagen, Itaú, Bradesco, Banco Votorantim, entre outros.
				Seguimos as diretrizes da Resolução nº 4.656 e da Resolução nº 4.935 do
				Central do Brasil. <b>Informações adicionais sobre o empréstimo:</b>{' '}
				Efetivo Total (CET) é o custo total que se refere ao empréstimo,
				incluindo a taxa de juros somados a todas as despesas, encargos e
				impostos (IOF). A CET da operação pode sofrer variação de acordo com os
				critérios de análise de de cada instituição financeira parceira e será
				informada durante a oferta e contratação do empréstimo. Salientamos a
				importância da conferência do contrato antes da assinatura, evidenciando
				a sua ciência dos valores ali descritos antes da contratação do crédito.
				O preenchimento da simulação de empréstimo não gera compromisso em
				finalizar a operação com nenhuma das instituições financeiras parceiras.
				A concessão do crédito está sujeita à análise pelas instituições
				financeiras parceiras.{' '}
				<u>
					<b>
						Nunca solicitamos qualquer taxa ou depósito para liberação de
						empréstimos ou financiamentos. Fique atento com fraudes e havendo
						dúvidas, entre em contato através da nossa central de atendimento.
					</b>
				</u>
			</p>
		);
	} else {
		content = (
			<p>
				Os produtos financeiros da Sinosserra Financeira são comercializados
				através da Sinosserra Promotora de Vendas e Serviços Financeiros Ltda...{' '}
				<span
					onClick={() => {
						setFullFooterTextShown(true);
					}}
					style={{ color: '#012d5c', fontWeight: 'bold' }}
				>
					Ler mais
				</span>
			</p>
		);
	}

	return <div>{content}</div>;
};

const EndCard = () => {
	return (
		<Styled.EndCard>
			<Styled.ContainerFooter>
				<p>Como foi sua expêriencia?</p>
				<a href="mailto:creditopessoal@sinosserrafinanceira.com.br">
					creditopessoal@sinosserrafinanceira.com.br
				</a>
			</Styled.ContainerFooter>
			<p>Siga a Sinosserra</p>
			<Styled.ContainerFollow>
				<a
					href="https://www.facebook.com/SinosserraFinanceira"
					target="_blank"
					rel="noreferrer"
				>
					<img src={facebookImg} alt="" />
				</a>
				<a
					href="https://www.instagram.com/sinosserra.financeira/"
					target="_blank"
					rel="noreferrer"
				>
					<img src={instagramImg} alt="" />
				</a>
				<a
					href="https://www.linkedin.com/company/sinosserra-financeira"
					target="_blank"
					rel="noreferrer"
				>
					<img src={linkedinImg} alt="" />
				</a>
			</Styled.ContainerFollow>
			<Styled.DescriptionFooter>
				<p>
					Sociedade de Crédito, Financiamento e Investimento CNPJ:
					22.639.377/0001-28
				</p>
				<p>
					Av. Pedro Adams Filho, 3790 Sala 401 - Bairro Pátria Nova Novo
					Hamburgo - RS
				</p>
			</Styled.DescriptionFooter>
			<Styled.DescriptionFooter>
				<p>
					<a href="https://sinosserrafinanceira.com.br/img/politica-privacidade-grupo-sinosserra.pdf">
						Política de privacidade
					</a>
				</p>
				<p>
					<a href="https://sinosserra-privacy.my.onetrust.com/webform/f4dcef9a-9072-4fcd-9a0c-a6e44720ffcf/13298f70-6907-46a2-8660-94b604c9445e">
						Canal DPO
					</a>
				</p>
			</Styled.DescriptionFooter>
			<Styled.ContainerFooterSmall>
				<Footer />
			</Styled.ContainerFooterSmall>
		</Styled.EndCard>
	);
};

export default EndCard;
