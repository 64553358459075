import { StepsContext } from './../providers/StepsProvider';
import { useContext } from 'react';

const useSteps = () => {
	const context = useContext(StepsContext);

	if (!context) throw new Error('useSteps must be used with StepsContext');

	return context;
};

export default useSteps;
