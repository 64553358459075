import { InputHTMLAttributes, useState } from 'react';
import * as Styled from './styles';

type CheckboxProps = {
	id: string;
	name: string;
	children: React.ReactNode;
	value?: boolean;
	column?: boolean;
	onCheck?: (status: boolean) => void;
};

const Checkbox = ({
	id,
	children,
	name,
	onCheck,
	value,
	column = false
}: CheckboxProps) => {
	const [checked, setChecked] = useState(value);
	const onChange = () => {
		const status = !checked;
		setChecked(status);

		if (onCheck) onCheck(status);
	};
	return (
		<Styled.Wrapper column={column}>
			<Styled.Checkbox
				id={id}
				name={name}
				type="checkbox"
				onChange={onChange}
				checked={checked}
			/>
			<Styled.Label htmlFor={id}>{children}</Styled.Label>
		</Styled.Wrapper>
	);
};

export default Checkbox;
