import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
	${({ theme }) => css`
		width: 100%;
		margin-top: 1.6rem;
		padding: 1.6rem 2rem;
		border: 3px solid ${theme.colors.gray};
		border-radius: 0.5rem;
		display: flex;
		flex-direction: column;
		align-items: center;
	`}
`;
export const Image = styled.img`
	width: 5rem;
`;

export const TextWraapper = styled.div`
	margin-top: 1rem;
`;

export const Paragraph = styled.p`
	${({ theme }) => css`
		text-align: center;
		font-size: 1.4rem;
		color: ${theme.colors.blue.weight};
		font-style: italic;
		font-family: ${theme.font.family.medium};
		margin-bottom: 1.5rem;
	`}
`;
export const AlertMessage = styled.span`
	${({ theme }) => css`
		display: block;
		max-width: 39rem;
		font-size: 1.2rem;

		text-align: center;
		font-family: ${theme.font.family.light};
		color: ${theme.colors.blue.weight};
	`}
`;
