import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import history from 'service/history';

const apiBff = axios.create({
	baseURL: process.env.REACT_APP_BASEURL_BACKEND_API
});

apiBff.interceptors.request.use(
	// response => {
	// 	return response;
	// },
	(request: AxiosRequestConfig) => {
		const token = localStorage.getItem('token');
		apiBff.defaults.headers.Authorization = `Bearer ${token}`;

		return request;
	},
	(error: AxiosError) => {
		if (error.response?.status === 401) {
			if (error.response?.data?.error === 'Unauthorized') {
				localStorage.removeItem('token');
				apiBff.defaults.headers.Authorization = undefined;

				history.push({
					pathname: '/',
					search: location.search
				});
			}
		}
		return Promise.reject(error);
	}
);

export default apiBff;
