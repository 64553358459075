import styled, { css, DefaultTheme } from 'styled-components';

const stepsModifiers = {
	common: (theme: DefaultTheme) => css`
		border: 0.1rem solid ${theme.colors.blue.weight};
		width: 2.5rem;
		height: 2.5rem;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
	`,

	active: (theme: DefaultTheme) => css`
		background: ${theme.colors.blue.weight};
		span {
			color: ${theme.colors.white};
			font-family: ${theme.font.family.bold};
		}
	`,

	normal: (theme: DefaultTheme) => css`
		background: none;
		span {
			color: ${theme.colors.blue.weight};
			font-family: ${theme.font.family.superbold};
		}
	`,

	completed: (theme: DefaultTheme) => css`
		background: ${theme.colors.blue.weight};
	`
};

type StepProps = { active: boolean; completed: boolean };

export const Step = styled.div<StepProps>`
	${({ theme, active, completed }) => css`
		${stepsModifiers.common(theme)}
		${active && stepsModifiers.active(theme)}
		${!active && stepsModifiers.normal(theme)}
		${completed && stepsModifiers.completed(theme)}
	`}
`;
