import * as Styled from './styles';
import messageSvg from '../../../../assets/icons/message.svg';
import data from './data';

const CommentsCard = () => {
	return (
		<Styled.Card>
			{data.map((item, index) => (
				<Styled.Options key={index}>
					<Styled.Bubble>
						<img src={item.image} />
					</Styled.Bubble>
					<p>{item.comment}</p>
					{item.author && <span>{item.author}</span>}
				</Styled.Options>
			))}
		</Styled.Card>
	);
};

export default CommentsCard;
