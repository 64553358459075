import api from './api';

const RESOURCE = '';

const getLocations = async <T>(): Promise<T> => {
	const response = await api.get<T>(RESOURCE);
	return response.data;
};

const locationsService = {
	getLocations
};

export default locationsService;
