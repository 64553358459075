import ReactInputCurrency from 'react-intl-currency-input';
import styled, { css, DefaultTheme } from 'styled-components';

type StyledInputProps = {
	required?: boolean;
};

const InputModifiers = {
	required: (theme: DefaultTheme) => css`
		+ ${Label}::after {
			content: '*';
			color: ${theme.colors.blue.weight};
			font-family: ${theme.font.family.medium};
		}
	`
};

export const Container = styled.div`
	display: flex;
	flex-direction: column-reverse;
	width: 100%;
`;

export const Input = styled.input<StyledInputProps>`
	${({ theme, required }) => css`
		width: 100%;
		height: 3rem;
		padding: 0 1.2rem;
		font-size: ${theme.font.sizes.xsmall};
		font-family: ${theme.font.family.medium}, sans-serif;

		color: ${theme.colors.blue.weight};
		background-color: ${theme.colors.gray};

		border: 0;
		border-radius: 3px;

		${required && InputModifiers.required(theme)}
	`}
`;

export const Label = styled.label`
	${({ theme }) => css`
		color: ${theme.colors.blue.weight};
		font-size: ${theme.font.sizes.xsmall};
		font-family: ${theme.font.family.regular}, sans-serif;
	`}
`;

export const HelperText = styled.p<{ error?: boolean }>`
	${({ theme, error }) => css`
		margin-top: 0.5rem;

		color: ${error ? theme.colors.error : theme.colors.blue.weight};

		font-size: 1.125rem;
		font-family: ${theme.font.family.regular}, sans-serif;
		font-style: italic;
	`}
`;

export const InputCurrency = styled(ReactInputCurrency)`
	appearance: none;
	${({ theme, required }) => css`
		width: 100%;
		height: 3rem;
		padding: 0 1.2rem;
		font-size: ${theme.font.sizes.xsmall};
		font-family: ${theme.font.family.medium}, sans-serif;

		color: ${theme.colors.blue.weight};
		background-color: ${theme.colors.gray};

		border: 0;
		border-radius: 3px;

		${required && InputModifiers.required(theme)}
	`}
`;
