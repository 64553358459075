import axios, { AxiosError } from 'axios';
import history from 'service/history';

const apiKeyClock = axios.create({
	baseURL: process.env.REACT_APP_BASEURL_BACKEND_KEYCLOAK
});

apiKeyClock.interceptors.response.use(
	response => {
		return response;
	},
	(error: AxiosError) => {
		if (error.response?.status === 401) {
			if (error.response?.data?.error === 'Unauthorized') {
				localStorage.removeItem('token');
				apiKeyClock.defaults.headers.Authorization = undefined;

				history.push({
					pathname: '/',
					search: location.search
				});
			}
		}

		return Promise.reject(error);
	}
);

export default apiKeyClock;
