import { Link } from 'react-router-dom';
import styled, { css, DefaultTheme } from 'styled-components';
import { HeaderProps } from '.';

type StyledHeaderProps = Omit<HeaderProps, 'nonSteps'>;

const headerModifiers = {
	translucent: () => css`
		background: transparent;
	`,
	small: () => css`
		height: 9.6rem;
		display: flex;
		align-items: center;
	`,
	large: (theme: DefaultTheme) => css`
		display: flex;
		flex-direction: column;
		padding-top: ${theme.spacings.medium};
		padding-bottom: ${theme.spacings.medium};
	`
};

export const Header = styled.header<StyledHeaderProps>`
	${({ theme, translucent, headerSize }) => css`
		padding-left: ${theme.spacings.small};
		padding-right: ${theme.spacings.small};
		background: ${theme.colors.gradiant};

		${translucent && headerModifiers.translucent()}
		${!!headerSize && headerModifiers[headerSize](theme)}
	`}
`;
export const Image = styled(Link)`
	width: 18rem;
	height: 3.7rem;
`;

export const Icon = styled.button`
	${({ theme }) => css`
		border: 0;
		background: none;
		width: 3rem;

		svg {
			color: ${theme.colors.white};
		}
	`}
`;

export const Section = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const Steps = styled.div`
	${({ theme }) => css`
		max-width: 200px;
		margin: 0 auto;

		display: flex;
		justify-content: space-between;
		margin-top: 1.7rem;

		${theme.media.queryDesktop('1000')} {
			max-width: 45rem;
			margin: 4.6rem auto 0;

			div {
				margin: 0 1rem;
			}
		}
	`}
`;
