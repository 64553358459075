import payOffImg from '../../../../assets/pay_off_image.png';
import investImg from '../../../../assets/invest_image.png';
import realizeImg from '../../../../assets/realize_image.png';
import yourHouseImg from '../../../../assets/your_house_image.png';

export default [
	{
		textBold: 'Quitar',
		textLight: 'suas divídas',
		img: payOffImg
	},
	{
		textBold: 'Investir​',
		textLight: 'no seu próprio negócio',
		img: investImg
	},
	{
		textBold: 'Realizar',
		textLight: 'sonhos como viagens, cirurgias e eventos​',
		img: realizeImg
	},
	{
		textBold: 'Sua casa',
		textLight: 'Reformar ou comprar​',
		img: yourHouseImg
	}
];
