import useClickOutside from 'hooks/useClickOutside';
import React, { SelectHTMLAttributes, useRef, useState } from 'react';
import * as BsIcons from 'react-icons/bs';
import * as Styled from './styles';

type OptionsProps = {
	id: string | number;
	name: string;
};

export interface SelectProps extends SelectHTMLAttributes<HTMLDivElement> {
	id: string;
	label?: string;
	options?: Array<OptionsProps>;
	disabled?: boolean;
	error?: string;
	value?: string;
	onValue: (value: string) => void;
}

const SelectField = ({
	id,
	label,
	options,
	disabled = false,
	onValue,
	value,
	error,
	...props
}: SelectProps) => {
	const [isExpanded, setExpanded] = useState<boolean>(false);

	const wrapperRef = useRef<HTMLDivElement>(null);

	const toggleSelect = () => {
		setExpanded(!isExpanded);
	};

	const collapseContainer = () => {
		setExpanded(false);
	};

	const handleChange = (value: string) => {
		setExpanded(false);
		!!onValue && onValue(value);
	};

	useClickOutside(wrapperRef, () => {
		collapseContainer();
	});

	return (
		<Styled.Container ref={wrapperRef}>
			<Styled.Label disabled={disabled} htmlFor={id}>
				{label}
			</Styled.Label>
			<Styled.Select
				alignment={!!value}
				id={id}
				onClick={() => !disabled && toggleSelect()}
				{...props}
			>
				{value}
				<BsIcons.BsChevronDown />
			</Styled.Select>
			{!!error && (
				<Styled.HelperText error={!!error}>{error}</Styled.HelperText>
			)}
			{isExpanded && (
				<Styled.DropdownContainer>
					{options?.map((element: OptionsProps, index: number) => (
						<Styled.Option
							onClick={() => {
								handleChange(element.name);
							}}
							key={index}
						>
							{element.name}
						</Styled.Option>
					))}
				</Styled.DropdownContainer>
			)}
		</Styled.Container>
	);
};

export default SelectField;
