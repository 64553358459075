import styled, { css, DefaultTheme } from 'styled-components';
import { ButtonProps } from '.';

type wrapperProps = Pick<ButtonProps, 'themeBtn'>;

const wrapperModifers = {
	primary: (theme: DefaultTheme) => css`
		color: ${theme.colors.white};
		background: ${theme.colors.green};
	`,
	secondary: (theme: DefaultTheme) => css`
		color: ${theme.colors.green};
		border: solid 1px ${theme.colors.green};
	`,
	disabled: (theme: DefaultTheme) => css`
		color: ${theme.colors.white};
		background: ${theme.colors.blue.disabled};
	`
};

export const Button = styled.button<wrapperProps>`
	${({ theme, themeBtn }) => css`
		width: 100%;
		height: 4rem;
		display: flex;
		justify-content: center;
		align-items: center;

		border-radius: 2.5rem;
		font-size: ${theme.font.sizes.small};
		font-family: ${theme.font.family.medium};
		background: transparent;
		border: 0;

		cursor: pointer;

		${!!themeBtn && wrapperModifers[themeBtn](theme)}
	`}
`;
