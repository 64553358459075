import * as Styled from './styles';
import data from './data';

const HireCreditCard = () => {
	return (
		<Styled.Card>
			{data.map((item, index) => (
				<Styled.OptionsCard key={index}>
					<img src={item.img} />
					{item.text}
				</Styled.OptionsCard>
			))}
		</Styled.Card>
	);
};

export default HireCreditCard;
