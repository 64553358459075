import { InputHTMLAttributes } from 'react';
import * as Styled from './styles';

type RadioInputProps = InputHTMLAttributes<HTMLInputElement>;

type RadioProps = {
	id: string;
	name: string;
	label: string;
	disabled?: boolean;
} & RadioInputProps;

const RadioButton = ({ id, name, label, disabled, ...props }: RadioProps) => (
	<Styled.Wrapper>
		<Styled.Label disabled={disabled} htmlFor={id}>
			<input disabled={disabled} type="radio" name={name} id={id} {...props} />
			<Styled.RadioContainer>
				<Styled.Circle></Styled.Circle>
			</Styled.RadioContainer>
			{label}
		</Styled.Label>
	</Styled.Wrapper>
);

export default RadioButton;
