import styled, { css } from 'styled-components';

export const Wrapper = styled.div``;

export const Contents = styled.div`
	${({ theme }) => css`
		width: 100%;
		padding: 2.3rem 4rem 12rem 4rem;

		${theme.media.queryDesktop('1000')} {
			width: 50rem;
			margin: 0 auto;
		}
	`}
`;

export const GridInput = styled.div`
	${({ theme }) => css`
		margin-top: ${theme.spacings.xsmall};

		display: grid;
		grid-template-columns: 0.8fr 1fr 1fr;
		gap: 0.4rem;

		align-items: flex-end;

		> button {
			height: 3rem;

			font-family: ${theme.font.family.medium}, sans-serif;
			font-weight: 500;

			border-radius: 3px;
		}

		> button:nth-child(2) {
			font-size: ${theme.font.sizes.xsmall};

			background-color: ${theme.colors.blue.pacificBlue};
			color: ${theme.colors.white};

			border: 1px solid ${theme.colors.blue.pacificBlue};

			transition: all 0.2s ease;

			&:disabled {
				background-color: ${theme.colors.blue.disabled};
				border: 1px solid ${theme.colors.blue.disabled};
			}
		}

		> button:nth-child(3) {
			font-size: 1rem;
			background-color: ${theme.colors.white};
			color: ${theme.colors.blue.pacificBlue};

			border: 1px solid ${theme.colors.blue.pacificBlue};
		}

		+ p {
			margin-top: 0.3rem;

			font-family: ${theme.font.family.light}, sans-serif;
			font-size: 0.9rem;
			font-weight: 100;
			font-style: italic;

			color: ${theme.colors.blue.weight};
		}
	`}
`;

export const InputContainer = styled.div`
	${({ theme }) => css`
		margin-top: ${theme.spacings.xxsmall};

		display: grid;
		grid-template-columns: repeat(1, 1fr);
		gap: 1.2rem ${theme.spacings.xxsmall};
	`}
`;

export const RadioContainer = styled.div<{ disabled?: boolean }>`
	${({ theme, disabled }) => css`
		margin-top: ${theme.spacings.xsmall};

		display: grid;
		grid-template-columns: 1fr 1fr;

		> p {
			margin-bottom: 1.2rem;

			grid-column: 1/3;

			font-family: ${theme.font.family.regular}, sans-serif;
			font-size: 1.3rem;
			font-weight: 400;

			color: ${disabled
				? theme.colors.blue.disabled
				: theme.colors.blue.weight};
		}
	`}
`;

export const HideInputGrid = styled.div`
	${({ theme }) => css`
		margin-top: ${theme.spacings.xsmall};

		display: grid;
		grid-template-columns: 1fr;
		gap: 1.2rem ${theme.spacings.xxsmall};
	`}
`;

export const ButtonContainer = styled.div`
	${({ theme }) => css`
		margin-top: ${theme.spacings.small};

		display: grid;
		grid-template-columns: 0.3fr 1fr;

		gap: 12px;

		button {
			font-size: 1.5rem;
			font-weight: 500;
		}

		> button:nth-child(2) {
			svg {
				margin-left: 1.8rem;
			}
		}
	`}
`;

export const Error = styled.span`
	${({ theme }) => css`
		margin-top: 0.5rem;

		color: ${theme.colors.error};

		font-size: 1rem;
		font-family: ${theme.font.family.regular}, sans-serif;
		font-style: italic;
	`}
`;
