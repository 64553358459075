export default [
	{
		type: 'textcurrency',
		id: 'moneyValue',
		name: 'howMuchNeedYou',
		label: 'De quanto você precisa?',
		helper: 'Valor mínimo de R$ 5.000 e máximo de R$ 150.000'
	},
	{
		type: 'autocomplete',
		id: 'reason',
		name: 'reasonForLoan',
		label: 'Motivo do empréstimo',
		options: [
			{
				id: 'saude',
				name: 'Cuidados com a saúde'
			},
			{
				id: 'pagamentoDividas',
				name: 'Pagamentos de dividas'
			},
			{
				id: 'realizarSonho',
				name: 'Realizar Sonho'
			},
			{
				id: 'reformaCasa',
				name: 'Reformar a casa'
			},
			{
				id: 'aquisicaoDeBens',
				name: 'Aquisição de bens'
			},
			{
				id: 'investimento',
				name: 'Investimento'
			},
			{
				id: 'outros',
				name: 'Outros'
			}
		]
	},
	{
		type: 'text',
		id: 'fullname',
		name: 'fullname',
		label: 'Qual seu nome completo?'
	},
	{
		type: 'cpf',
		id: 'cpf',
		name: 'cpf',
		label: 'CPF'
	},
	{
		type: 'occupation',
		id: 'occupation',
		name: 'occupation',
		label: 'Tipo de ocupação',
		options: []
	},
	{
		type: 'cep',
		id: 'cep',
		name: 'cep',
		label: 'CEP'
	}
	// {
	// 	type: 'text',
	// 	id: 'email',
	// 	name: 'email',
	// 	label: 'Qual seu email?'
	// },
	// {
	// 	type: 'phone',
	// 	id: 'phone',
	// 	name: 'phone',
	// 	label: 'Número do celular'
	// }
];
