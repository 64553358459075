import carSvg from '../../assets/icons/car.svg';

import Button from 'components/Buttons';
import TextCard from './components/TextCard';
import HireCreditCard from './components/HireCreditCard';
import CreditCard from './components/CreditCard';
import CommentsCard from './components/CommentsCard';
import EndCard from './components/EndCard';

import * as Styled from './styles';
import history from 'service/history';

const LandingPage = () => {
	return (
		<Styled.Wrapper id="landingpage-container">
			<Styled.MainContainer>
				<Styled.ContainerPage id="credit-warranty">
					<h1>
						O que é <strong>crédito com garantia</strong> de veículo?
					</h1>
					<TextCard
						img={carSvg}
						title={
							<span>
								seu carro vale <strong>Dinheiro</strong>
							</span>
						}
					>
						<div className="iFrameContainer">
							<iframe src="https://www.youtube.com/embed/XdYu8pOIrTs" />
						</div>
						<p>
							É uma opção onde você utiliza o seu veículo como garantia de
							pagamento.
						</p>
						<p>
							Este valor possibilita as melhores taxas do mercado e você
							consegue transformar até 70% do valor do seu veículo em crédito.
						</p>
						<p>Tudo isso sem precisar se desfazer do seu bem!</p>
					</TextCard>
					<Styled.ButtonContainer>
						<Button
							onClick={() =>
								history.push({
									pathname: '/include-vehicle',
									search: location.search
								})
							}
						>
							Iniciar Simulação
						</Button>
					</Styled.ButtonContainer>
				</Styled.ContainerPage>
				<Styled.ContainerPage id="hire-credit">
					<h1>
						<strong>Por que</strong> contratar seu crédito com
						<strong> a Sinosserra?</strong>
					</h1>
					<HireCreditCard />
				</Styled.ContainerPage>
				<Styled.ContainerPage id="credit-card">
					<h1>
						O que <strong>você</strong> pode fazer <br />
						com <strong>seu crédito?</strong>
					</h1>
					<CreditCard />
				</Styled.ContainerPage>
				<Styled.ContainerPage id="comments-card">
					<h1>
						<strong>Depoimentos</strong> <br />
						dos nossos <strong>Clientes</strong>
					</h1>
					<CommentsCard />
				</Styled.ContainerPage>
				<Styled.ContainerPage id="text-card">
					<h1>
						<strong>Por que</strong> confiar na
						<strong> Sinosserra Financeira?</strong>
					</h1>
					<TextCard>
						<p>
							Somos uma financeira regulamentada pelo Banco Central que atua com
							financiamentos, investimentos e empréstimos desde 2015.
						</p>
						<p>
							Geramos mais de 300 mil contratos em 6 anos e contamos com 11mil
							contratos ativos.
						</p>
						<p>
							Fazemos parte do Grupo Sinosserra, um dos maiores grupos de
							concessionárias de veículos e serviços financeiros do Rio Grande
							do Sul, presente no mercado desde 1947.
						</p>
					</TextCard>
				</Styled.ContainerPage>
				<Styled.ContainerPage>
					<h1>
						<strong>Faça agora</strong> uma <br />
						simulação e confira as <strong>melhores condições</strong> <br />
						que cabem no seu bolso.
					</h1>
					<Styled.ButtonContainer>
						<Button
							onClick={() =>
								history.push({
									pathname: '/include-vehicle',
									search: location.search
								})
							}
						>
							Inicie já sua simulação
						</Button>
					</Styled.ButtonContainer>
					<Styled.TextContainer>
						<strong>É rápido, fácil e seguro.</strong>
						<span>Aproveite as menores taxas do mercado!</span>
					</Styled.TextContainer>
				</Styled.ContainerPage>
				<EndCard />
			</Styled.MainContainer>
		</Styled.Wrapper>
	);
};

export default LandingPage;
