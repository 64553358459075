import apiBff from './api';

const RESOURCE = 'public/rdstation';

const postRdStation = async <T>(body: any): Promise<T> => {
	const response = await apiBff.post<T>(`${RESOURCE}/event`, body);
	return response.data;
};

const appRdStationServices = {
	postRdStation
};

export default appRdStationServices;
