import styled, { css } from 'styled-components';

export const Wrapper = styled.div``;

export const RadioContainer = styled.div`
	${({ theme }) => css`
		width: 1.5rem;
		height: 1.5rem;
		margin-right: ${theme.spacings.xxsmall};

		border: 1px solid ${theme.colors.blue.weight};
		border-radius: 100%;

		z-index: -1;
	`}
`;

export const Circle = styled.div`
	${({ theme }) => css`
		width: 100%;
		height: 100%;
		padding: 0.4rem;

		display: block;

		background-color: ${theme.colors.blue.weight};

		border-radius: 100%;

		transform: scale(0);

		&::after {
			content: '';
			width: 100%;
			height: 100%;

			display: block;

			background-color: ${theme.colors.white};

			border-radius: 100%;
		}
	`}
`;

export const Label = styled.label<{ disabled?: boolean }>`
	${({ theme, disabled }) => css`
		display: inline-flex;
		align-items: center;
		font-family: ${theme.font.family.regular}, sans-serif;
		font-weight: 400;
		font-size: ${theme.font.sizes.xsmall};
		color: ${disabled ? theme.colors.blue.disabled : theme.colors.blue.weight};

		${RadioContainer} {
			${disabled &&
			css`
				border-color: ${theme.colors.blue.disabled};
			`}
		}

		cursor: pointer;

		> input {
			display: none;
		}

		> input:checked + ${RadioContainer} ${Circle} {
			transform: scale(1);
		}
	`}
`;
