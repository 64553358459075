import SpinnerLoading from 'components/SpinnerLoading';
import { ButtonHTMLAttributes } from 'react';
import * as Styled from './styles';

export type ButtonTypes = ButtonHTMLAttributes<HTMLButtonElement>;

export type ButtonProps = {
	themeBtn?: 'primary' | 'secondary' | 'disabled';
	loading?: boolean;
	disabled?: boolean;
} & ButtonTypes;

const Button = ({
	children,
	themeBtn = 'primary',
	loading = false,
	disabled = false,
	...props
}: ButtonProps) => (
	<Styled.Button
		disabled={disabled}
		themeBtn={disabled ? 'disabled' : themeBtn}
		{...props}
	>
		{!!children && !loading ? children : <SpinnerLoading color="white" />}
	</Styled.Button>
);

export default Button;
