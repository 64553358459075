import { ButtonHTMLAttributes } from 'react';
import * as Styled from './styles';

type ButtonTypes = ButtonHTMLAttributes<HTMLButtonElement>;

type ButtonProps = {
	id?: string;
	name?: string;
} & ButtonTypes;

const ButtonConsult = ({ id, name, children, ...props }: ButtonProps) => {
	return (
		<Styled.Wrapper>
			<Styled.Button id={id} name={name} {...props}>
				{children}
			</Styled.Button>
		</Styled.Wrapper>
	);
};

export default ButtonConsult;
