import { useLayoutEffect } from 'react';
import { Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import Routes from 'routes';
import theme from 'styles/theme';
import history from 'service/history';
import AppProvider from 'context/AppProvider';
import GlobalStyles from 'styles/GlobalStyles';

import keyclockServices from 'api/api-keyclock/services';
import apiShopKeeper from 'api/api-shopkeeper/api';
import apiAppdsv from 'api/api-appdsv/api';
import apiFinancial from 'api/api-financial/api';

function App() {
	useLayoutEffect(() => {
		(async () => {
			const { data } = await keyclockServices.getAuth({
				username: process.env.REACT_APP_USER_ACCOUNT!,
				password: process.env.REACT_APP_USER_PASSWORD!,
				grant_type: process.env.REACT_APP_GRAND_TYPE!,
				client_id: process.env.REACT_APP_CLIENT_ID!,
				client_secret: process.env.REACT_APP_CLIENT_SECRET!
			});

			apiShopKeeper.defaults.headers.Authorization = `Bearer ${data.access_token}`;
			apiAppdsv.defaults.headers.Authorization = `Bearer ${data.access_token}`;
			apiFinancial.defaults.headers.Authorization = `Bearer ${data.access_token}`;

			data?.access_token && localStorage.setItem('token', data.access_token);
		})();
	}, []);
	return (
		<Router history={history}>
			<AppProvider>
				<ThemeProvider theme={theme}>
					<GlobalStyles />
					<Routes />
				</ThemeProvider>
			</AppProvider>
		</Router>
	);
}

export default App;
