import * as Styled from './styles';

import error from 'assets/icons/error_parcel.svg';

const ParcelNotFoundError = () => {
	return (
		<Styled.Wrapper>
			<Styled.Image src={error} />
			<Styled.TextWraapper>
				<Styled.Paragraph>
					Ops! <br />
					Infelizmente nenhuma parcela foi encontrada
				</Styled.Paragraph>
				<Styled.AlertMessage>
					Atualmente são aceitos apenas veículos com fabricação máxima de 15
					anos
				</Styled.AlertMessage>
			</Styled.TextWraapper>
		</Styled.Wrapper>
	);
};

export default ParcelNotFoundError;
