import * as Styled from './styles';

import check from 'assets/icons/check.svg';

type StepProps = {
	step: string | number;
	completed: boolean;
	active: boolean;
};

const StepMarker = ({ step, completed, active }: StepProps) => {
	return (
		<Styled.Step completed={completed} active={active}>
			{completed && !active ? <img src={check} /> : <span>{step}</span>}
		</Styled.Step>
	);
};

export default StepMarker;
