import { ContextProps, ProviderProps, Steps } from 'context/models/steps';
import { createContext, useCallback, useState } from 'react';

import apiServices from 'api/api-appdsv/services';

export const StepsContext = createContext<ContextProps>({} as ContextProps);

const arraySteps: Steps[] = [
	{
		activePath: '/help-you',
		completed: false,
		step: 1
	},
	{
		activePath: '/vehicle-data',
		completed: false,
		step: 2
	},
	{
		activePath: '/switch-condition',
		completed: false,
		step: 3
	},
	{
		activePath: '/requested-analysis',
		completed: false,
		step: 4
	}
];

const StepsProvider = ({ children }: ProviderProps) => {
	const [steps, setSteps] = useState(arraySteps);
	const [stepZeroCompleted, setStepZeroCompleted] = useState(false);
	const [tipoOcupacao, setTipoOcupacao] = useState([]);

	const getTipoOcupacao = useCallback(async () => {
		const response = await apiServices.getTipoOcupacao<any>();
		const options = (response?.data !== null ? response?.data : []) || [];
		setTipoOcupacao(
			options.map((op: any) => {
				return { id: op.codigo, name: op.descricao };
			})
		);
	}, []);

	const changeCompletedState = (step: number, check: boolean) => {
		if (step === 0) {
			setStepZeroCompleted(check);
			return;
		}
		const newArraySteps = steps.map(item => {
			if (item.step === step) {
				return {
					...item,
					completed: check
				};
			}
			return item;
		});

		setSteps(newArraySteps);
	};

	const isCompletedState = (step: number): boolean => {
		if (step === 0) {
			return stepZeroCompleted;
		}
		for (const item of steps) {
			if (item.step === step) {
				return item.completed;
			}
		}
		return false;
	};

	return (
		<StepsContext.Provider
			value={{
				steps,
				isCompletedState,
				changeCompletedState,
				getTipoOcupacao,
				listaTipoOcupacao: tipoOcupacao
			}}
		>
			{children}
		</StepsContext.Provider>
	);
};

export default StepsProvider;
