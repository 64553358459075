import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import * as BiIcons from 'react-icons/bi';
import * as BsIcons from 'react-icons/bs';

import Header from 'components/Header';
import Button from 'components/Buttons';
import Checkbox from 'components/Checkbox';
import Heading from 'components/Heading';
import useMoney from 'context/hooks/useMoney';
import InputField from 'components/InputField';
import ParcelNotFound from './component/ParcelNotFound';
import InputMoneyVariant from 'components/InputMoneyVariant';
import ParcelNotFoundError from './component/ParcelNotFoundError';
import OptionsInstallmentsSlider from 'components/OptionInstallmentSlider';

import appdsvServices from 'api/api-appdsv/services';
import appSellFluxServices from 'api/api-appsellflux/services';

import useSteps from 'context/hooks/useSteps';
import useUserData from 'context/hooks/useUserData';
import useVehicule from 'context/hooks/useVehicule';

import { Address } from 'context/models/address';

import { analyticsEvent } from 'service/analytics';
import { formatCurrency } from 'functions';

import * as Styled from './styles';

import history from 'service/history';
import SpinnerLoading from 'components/SpinnerLoading';
import verifyNaN from 'utils/verifyNaN';

import useAnalytics from 'context/hooks/useAnalytics';

import * as CTX from '../../globalContext/globalContext';

import pdf from '../../assets/pdfs/termos de autorização.pdf';

import desmaskTelefone from 'utils/mask';

const SwitchCondition = () => {
	const {
		userValue,
		carPrice,
		installments,
		currentInstallment,
		getCurrentInstallmentValue,
		getInstallments,
		updateFinalValue,
		finalValue,
		installmentsErrors,
		clearInstallmentOption,
		installmentsLoading
	} = useMoney();
	const globalContext = useContext(CTX.Context);
	const {
		changeCompletedState,
		isCompletedState,
		getTipoOcupacao,
		listaTipoOcupacao
	} = useSteps();

	useEffect(() => {
		if (!isCompletedState(2)) {
			window.location.href = '/';
		}
	}, [isCompletedState]);

	const [address] = useState<Address>({
		CEP: globalContext.userCEP,
		UF: globalContext.userUF,
		address: globalContext.userAddress,
		city: globalContext.userCity,
		publicPlace: globalContext.userPublicPlace,
		check: globalContext.userAddressCheck
	});
	const { userData } = useUserData();
	const { inputValues, carInformations, locations } = useVehicule();
	const [form, setForm] = useState({
		acceptedCredit: true
	});

	const { analyticsValue, handleAddAnalyticsValue, params } = useAnalytics();
	const [valueError, setValueError] = useState('');

	useEffect(() => {
		getTipoOcupacao();
	}, [getTipoOcupacao]);

	const calculateInterestValue = () => {
		if (!currentInstallment.gravameValue && !currentInstallment.rateRegister) {
			return '0.00';
		}

		return formatCurrency(
			Math.round(
				currentInstallment.gravameValue + currentInstallment.rateRegister
			)
		).replace('R$', '');
	};

	const calculateValue = () => {
		if (!currentInstallment.gravameValue && !currentInstallment.rateRegister) {
			return formatCurrency(
				Number(finalValue) + verifyNaN(currentInstallment.valorIof)
			).replace('R$', '');
		}

		return formatCurrency(
			Number(finalValue) +
				Math.round(
					currentInstallment.gravameValue + currentInstallment.rateRegister
				) +
				currentInstallment.valorIof
		).replace('R$', '');
	};

	const backRoute = () => {
		clearInstallmentOption();
		updateFinalValue(undefined);
		changeCompletedState(3, false);
		history.push({
			pathname: '/vehicle-data',
			search: location.search
		});
	};

	const handleSubmit = () => {
		const carPriceNumber = Number(carPrice);

		const analyticsBody = {
			...analyticsValue,
			valorFinal: finalValue
		};

		analyticsEvent('ck_avancar_terceira_etapa', analyticsBody);

		handleAddAnalyticsValue(analyticsBody);

		if (finalValue! < 5000) {
			setValueError('Valor deve ser maior ou igual a R$ 5.000');
			updateFinalValue(5000);
			return;
		} else if (finalValue! > 150000) {
			setValueError('Valor deve estar entre R$ 5.000 e R$ 150.000');
			updateFinalValue(150000);
			return;
		} else {
			const valMax = Number(((carPriceNumber * 70) / 100).toFixed());

			if (finalValue! > valMax) {
				updateFinalValue(valMax);
				return;
			}
		}

		setValueError('');

		handleSubmitCreditAnalyze();

		changeCompletedState(3, true);

		history.push({
			pathname: '/requested-analysis',
			search: location.search
		});
	};

	const handleSubmitCreditAnalyze = async () => {
		try {
			const cpfDesmasked = globalContext.cpfUsuario
				.toString()
				.replaceAll('.', '')
				.replaceAll('-', '');
			const ufVehicle =
				locations.find(x => x?.name === inputValues?.location) || null;
			const tipoOcupacaoObj =
				listaTipoOcupacao.find(x => x?.name === globalContext?.occupation) ||
				null;
			const { ddd, phone: phoneDesmask } = desmaskTelefone(
				String(globalContext.phone)
			);

			if (globalContext.empresaId !== '' && globalContext.cpfUsuario !== '') {
				const body = {
					codigoFipe: carInformations.fipeCode,
					codAno: carInformations.yearModel || inputValues.modelYear,
					precoFipe: carInformations.originalPrice,
					valorEmprestimo: Number(finalValue),
					seqTabelaFinanceira: currentInstallment.seqTabelaFinanceira,
					prazo: currentInstallment.instalment,
					valorParcela: Number(
						currentInstallment.value
							.toString()
							.replaceAll('.', '')
							.replaceAll(',', '')
					),
					cpf: cpfDesmasked,
					empresaId: globalContext.empresaId,
					cpfUsuario: globalContext.cpfUsuario,
					nomeCliente: userData.fullname,
					placa: carInformations.licensePlate,
					email: userData.email,
					telefone: phoneDesmask,
					ddd,
					usuario: '',
					motivoEmprestimo: userData?.reasonForLoan,
					preferenciaContatoWhatsapp: globalContext.preferenciaContatoWhatsapp,
					preferenciaContatoTelefone: globalContext.preferenciaContatoTelefone,
					preferenciaContatoEmail: globalContext.preferenciaContatoEmail,
					autorizaConsultaCredito: form.acceptedCredit,
					receberInformacoesProdutosServicos: globalContext.about,
					cepCliente: Number(address.CEP.toString().replace('-', '')),
					ufCliente: address.UF,
					cidadeCliente: address.city,
					ufVeiculo: ufVehicle?.sigla || null,
					veiculoQuitado: inputValues.paidoff,
					valorParcelaAtual: Number(inputValues.valueParcel),
					quantidadeParcelasFaltantes: Number(inputValues.amountParcel) || null,
					tipoOcupacao: tipoOcupacaoObj?.id || null,
					marcaVeiculo: inputValues.brand,
					modeloVeiculo: inputValues.model,
					fonte: params.utm_source || '',
					midia: params.utm_medium || '',
					campanha: params.utm_campaign || ''
				};
				const analyticsBody = {
					...analyticsValue,
					empresaId: globalContext.empresaId,
					cpfUsuario: globalContext.cpfUsuario,
					nome: userData.fullname,
					email: userData.email,
					cpf_cnpj_client: globalContext.cpfUsuario,
					valorParcelaAtual: Number(currentInstallment.value),
					celular: globalContext.phone,
					tipoOcupacao: globalContext.occupation,
					cep: address.CEP,
					uf: address.UF,
					endereco: address.address,
					cidade: address.city,
					receberInformacoesProdutosServicos: globalContext.about,
					autorizarCredito: form.acceptedCredit,
					marca: inputValues.brand,
					modelo: inputValues.model,
					placa: carInformations.licensePlate,
					anoFabricacao: inputValues.modelYear,
					anoModelo: inputValues.modelYear,
					localidade: inputValues.location,
					quitacao: inputValues.paidoff,
					valorParcela: inputValues.valueParcel,
					quantidadeParcela: Number(inputValues.amountParcel),
					traffic_source: params.utm_source || '',
					traffic_medium: params.utm_medium || '',
					traffic_campaign: params.utm_campaign || ''
				};
				const includeLeadBody = {
					name: userData.fullname,
					email: userData.email,
					phone: globalContext.phone,
					url: 'https://financeira.gruposinosserra.com.br/requested-analysis',
					tags: ['virou-lead-etapa-8']
				};
				//changeCompletedState(4, true);
				await appdsvServices.postEmp(body);
				await appSellFluxServices.postIncludeLead(includeLeadBody);
				analyticsEvent('ck_avancar_quarta_etapa', analyticsBody);
				handleAddAnalyticsValue(analyticsValue);
			} else {
				const body = {
					codigoFipe: carInformations.fipeCode,
					codAno: carInformations.yearModel || inputValues.modelYear,
					precoFipe: carInformations.originalPrice,
					valorEmprestimo: Number(finalValue),
					seqTabelaFinanceira: currentInstallment.seqTabelaFinanceira,
					prazo: currentInstallment.instalment,
					valorParcela: Number(
						currentInstallment.value
							.toString()
							.replaceAll('.', '')
							.replaceAll(',', '')
					),
					cpf: cpfDesmasked,
					nomeCliente: userData.fullname,
					placa: carInformations.licensePlate,
					email: userData.email,
					telefone: phoneDesmask,
					ddd,
					usuario: '',
					motivoEmprestimo: userData?.reasonForLoan,
					preferenciaContatoWhatsapp: globalContext.preferenciaContatoWhatsapp,
					preferenciaContatoTelefone: globalContext.preferenciaContatoTelefone,
					preferenciaContatoEmail: globalContext.preferenciaContatoEmail,
					autorizaConsultaCredito: form.acceptedCredit,
					receberInformacoesProdutosServicos: globalContext.about,
					cepCliente: Number(address.CEP.toString().replace('-', '')),
					ufCliente: address.UF,
					cidadeCliente: address.city,
					ufVeiculo: ufVehicle?.sigla || null,
					veiculoQuitado: inputValues.paidoff,
					valorParcelaAtual: Number(inputValues.valueParcel),
					quantidadeParcelasFaltantes: Number(inputValues.amountParcel) || null,
					tipoOcupacao: tipoOcupacaoObj?.id || null,
					marcaVeiculo: inputValues.brand,
					modeloVeiculo: inputValues.model,
					fonte: params.utm_source || '',
					midia: params.utm_medium || '',
					campanha: params.utm_campaign || ''
				};
				const analyticsBody = {
					...analyticsValue,
					nome: userData.fullname,
					email: userData.email,
					cpf_cnpj_client: globalContext.cpfUsuario,
					valorParcelaAtual: Number(currentInstallment.value),
					celular: userData.phone,
					tipoOcupacao: globalContext.occupation,
					cep: address.CEP,
					uf: address.UF,
					endereco: address.address,
					cidade: address.city,
					receberInformacoesProdutosServicos: globalContext.about,
					autorizarCredito: form.acceptedCredit,
					marca: inputValues.brand,
					modelo: inputValues.model,
					placa: carInformations.licensePlate,
					anoFabricacao: inputValues.modelYear,
					anoModelo: inputValues.modelYear,
					localidade: inputValues.location,
					quitacao: inputValues.paidoff,
					valorParcela: inputValues.valueParcel,
					quantidadeParcela: Number(inputValues.amountParcel),
					traffic_source: params.utm_source || '',
					traffic_medium: params.utm_medium || '',
					traffic_campaign: params.utm_campaign || ''
				};
				const includeLeadBody = {
					name: userData.fullname,
					email: globalContext.email,
					phone: globalContext.phone,
					url: 'https://financeira.gruposinosserra.com.br/switch-condition',
					tags: ['virou-lead-etapa-8']
				};
				//changeCompletedState(4, true);
				await appdsvServices.postEmp(body);
				await appSellFluxServices.postIncludeLead(includeLeadBody);
				analyticsEvent('ck_avancar_quarta_etapa', analyticsBody);
				handleAddAnalyticsValue(analyticsValue);
			}
		} catch (err) {
			console.error(err);
			//setButtonLoading(false);
		} finally {
			//setButtonLoading(false);
		}
	};

	useLayoutEffect(() => {
		const carPriceNumber = Number(carPrice);
		const userValueNumber = Number(userValue);
		const percentCarPrice = (Number(carPriceNumber) * 70) / 100;

		if (!carPriceNumber) {
			updateFinalValue(userValueNumber);
			return;
		}

		if (carPriceNumber) {
			if (percentCarPrice > 150000) {
				updateFinalValue(150000);
				return;
			} else if (userValueNumber < percentCarPrice) {
				updateFinalValue(userValueNumber);
				return;
			} else {
				updateFinalValue(percentCarPrice);
			}
		}
	}, []);

	const onBlurInput = (value: number) => {
		if (value < 5000) {
			setValueError('Valor deve ser maior que R$ 5.000');
			updateFinalValue(5000);
			return;
		}

		setValueError('');
	};

	useEffect(() => {
		getInstallments(carInformations, inputValues.modelYear);
	}, [getInstallments, carInformations, inputValues.modelYear]);

	return (
		<Styled.Wrapper>
			<Header headerSize="large" />
			<Styled.Content>
				<Heading>Escolha a condição desejada</Heading>

				{carPrice ? (
					<Styled.Paragraph>
						<strong>{userData?.fullname}</strong>, usando seu veículo{' '}
						<strong>
							{inputValues.model} {inputValues.modelYear}
						</strong>{' '}
						como garantia, conseguimos liberar até 70% do valor da FIPE ou seja
						até{' '}
						{(Number(carPrice) * 70) / 100 > 150000
							? formatCurrency(150000)
							: formatCurrency((Number(carPrice) * 70) / 100)}
						.
					</Styled.Paragraph>
				) : (
					<Styled.Paragraph>
						<strong>{userData?.fullname}</strong>, usando seu veículo{' '}
						<strong>
							{inputValues.model} {inputValues.modelYear}
						</strong>{' '}
						como garantia, conseguimos liberar até 70% do valor da FIPE.
					</Styled.Paragraph>
				)}

				<Styled.InputWrapper>
					<InputMoneyVariant
						id="inputMoneyVariant"
						onBlur={onBlurInput}
						error={valueError}
						onChange={updateFinalValue}
						initialValue={finalValue!}
						updateValueMinMax={updateFinalValue}
						max={
							!carPrice ? 150000 : (carInformations.originalPrice * 70) / 100
						}
					/>
				</Styled.InputWrapper>
			</Styled.Content>
			<Styled.Content customPadding="0 4rem 2rem">
				<Heading>Tabelas e taxas* a partir de:</Heading>
			</Styled.Content>

			{installmentsLoading ? (
				<SpinnerLoading />
			) : (
				<React.Fragment>
					{installments.length > 0 && !installmentsLoading && (
						<Styled.Content customPadding="0 0 2rem">
							<OptionsInstallmentsSlider
								handleFocus={getCurrentInstallmentValue}
								options={installments}
							/>
						</Styled.Content>
					)}
					{installments.length === 0 && !installmentsLoading && (
						<Styled.Content customPadding="0 4rem 2rem">
							{installmentsErrors && !installmentsLoading ? (
								<ParcelNotFoundError />
							) : (
								<ParcelNotFound
									onUpdate={() =>
										getInstallments(carInformations, inputValues.modelYear)
									}
								/>
							)}
						</Styled.Content>
					)}
				</React.Fragment>
			)}

			<Styled.Content customPadding="1rem 4rem 1rem">
				<Heading icon={<BiIcons.BiDollarCircle size={18} />}>
					Código promocional
				</Heading>
				<Styled.Form>
					<InputField
						placeholder="Insira seu código aqui!"
						name="promotionalCode"
						id="promotionalCode"
						disabled
					/>
					<Styled.CalculateButton type="button">
						Calcular
					</Styled.CalculateButton>
				</Styled.Form>
			</Styled.Content>

			<Styled.Content customPadding="0 4rem">
				<Styled.Table>
					<Styled.TableRow>
						<Styled.Key>Valor liberado</Styled.Key>
						<Styled.Value>
							R${' '}
							<span className="value">
								{formatCurrency(Number(finalValue)).replace('R$', '')}
							</span>
						</Styled.Value>
					</Styled.TableRow>
					<Styled.TableRow>
						<Styled.Key>Tarifas e Gravame</Styled.Key>
						<Styled.Value>
							R$ <span className="value">{calculateInterestValue()}</span>
						</Styled.Value>
					</Styled.TableRow>
					<Styled.TableRow>
						<Styled.Key>Valor impostos</Styled.Key>
						<Styled.Value>
							R${' '}
							<span className="value">
								{formatCurrency(verifyNaN(currentInstallment.valorIof)).replace(
									'R$',
									''
								)}
							</span>
						</Styled.Value>
					</Styled.TableRow>
					<Styled.TableRow>
						<Styled.Key>
							<span className="total">Total financiado</span>
						</Styled.Key>
						<Styled.Value>
							R$ <span className="value">{calculateValue()}</span>
						</Styled.Value>
					</Styled.TableRow>
					<Styled.TableRow>
						<Styled.Key>
							<span className="key-installments_1">Valor total</span>
							<br />
							<span className="key-installments_2">
								parcelas {currentInstallment.instalment || 1}x
							</span>
						</Styled.Key>
						<Styled.Value>
							R${' '}
							<span className="value">
								{currentInstallment.value || calculateValue()}
							</span>
						</Styled.Value>
					</Styled.TableRow>
					<Styled.Cet>
						Custo Efetivo Total (CET){' '}
						{!currentInstallment.percentualCet
							? 0
							: currentInstallment.percentualCet.toPrecision(2)}
						%{' '}
						<span
							style={{
								marginLeft: 5
							}}
						>
							a.m.
						</span>
					</Styled.Cet>
					<Styled.SubjectToAnalysis>
						Crédito sujeito à análise
					</Styled.SubjectToAnalysis>
				</Styled.Table>
			</Styled.Content>
			<Styled.AcceptedCredit>
				<Checkbox
					id="acceptCredit"
					name="accepted"
					value={Boolean(form.acceptedCredit)}
					onCheck={value => setForm({ ...form, acceptedCredit: value })}
				>
					Autorização consulta de crédito.
				</Checkbox>
				<Styled.Download onClick={() => window.open(pdf)}>
					Leia mais sobre autorização
				</Styled.Download>
			</Styled.AcceptedCredit>
			<Styled.ButtonsWrapper>
				<Button themeBtn="secondary" onClick={backRoute}>
					<BsIcons.BsArrowLeft size={24} />
				</Button>
				<Button
					disabled={!!installmentsErrors}
					themeBtn="primary"
					onClick={() => {
						if (finalValue! < 5000) return;

						handleSubmit();
					}}
				>
					Enviar simulação <BsIcons.BsArrowRight size={24} />
				</Button>
			</Styled.ButtonsWrapper>
		</Styled.Wrapper>
	);
};

export default SwitchCondition;
