import { createContext, SetStateAction, useState } from 'react';

type ContextProps = {
	empresaId: string;
	setEmpresaId: React.Dispatch<SetStateAction<string>>;
	email: string;
	setEmail: React.Dispatch<SetStateAction<string>>;
	phone: string;
	setPhone: React.Dispatch<SetStateAction<string>>;
	occupation: string;
	setOccupation: React.Dispatch<SetStateAction<string>>;
	paidoffVehicule: boolean;
	setPaidoffVehicule: React.Dispatch<SetStateAction<boolean>>;
	cpfUsuario: string;
	setCpfUsuario: React.Dispatch<SetStateAction<string>>;

	userCEP: string;
	setUserCEP: React.Dispatch<SetStateAction<string>>;
	userUF: string;
	setUserUF: React.Dispatch<SetStateAction<string>>;
	userCity: string;
	setUserCity: React.Dispatch<SetStateAction<string>>;
	userPublicPlace: string;
	setUserPublicPlace: React.Dispatch<SetStateAction<string>>;
	userAddress: string;
	setUserAddress: React.Dispatch<SetStateAction<string>>;
	userAddressCheck: boolean;
	setUserAddressCheck: React.Dispatch<SetStateAction<boolean>>;

	about: boolean;
	setAbout: React.Dispatch<SetStateAction<boolean>>;
	preferenciaContatoWhatsapp: boolean;
	setPreferenciaContatoWhatsapp: React.Dispatch<SetStateAction<boolean>>;
	preferenciaContatoTelefone: boolean;
	setPreferenciaContatoTelefone: React.Dispatch<SetStateAction<boolean>>;
	preferenciaContatoEmail: boolean;
	setPreferenciaContatoEmail: React.Dispatch<SetStateAction<boolean>>;
};

export const Context = createContext({} as ContextProps);

type ProviderProps = {
	children: React.ReactNode;
};

export const Provider = ({ children }: ProviderProps) => {
	const [empresaId, setEmpresaId] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [occupation, setOccupation] = useState('');
	const [paidoffVehicule, setPaidoffVehicule] = useState(false);
	const [cpfUsuario, setCpfUsuario] = useState('');
	const [userCEP, setUserCEP] = useState('');
	const [userUF, setUserUF] = useState('');
	const [userCity, setUserCity] = useState('');
	const [userPublicPlace, setUserPublicPlace] = useState('');
	const [userAddress, setUserAddress] = useState('');
	const [userAddressCheck, setUserAddressCheck] = useState(false);
	const [about, setAbout] = useState(true);
	const [preferenciaContatoWhatsapp, setPreferenciaContatoWhatsapp] =
		useState(true);
	const [preferenciaContatoTelefone, setPreferenciaContatoTelefone] =
		useState(true);
	const [preferenciaContatoEmail, setPreferenciaContatoEmail] = useState(true);

	return (
		<Context.Provider
			value={{
				empresaId,
				setEmpresaId,
				email,
				setEmail,
				phone,
				setPhone,
				occupation,
				setOccupation,
				paidoffVehicule,
				setPaidoffVehicule,
				cpfUsuario,
				setCpfUsuario,
				userCEP,
				setUserCEP,
				userUF,
				setUserUF,
				userCity,
				setUserCity,
				userPublicPlace,
				setUserPublicPlace,
				userAddress,
				setUserAddress,
				userAddressCheck,
				setUserAddressCheck,
				about,
				setAbout,
				preferenciaContatoWhatsapp,
				setPreferenciaContatoWhatsapp,
				preferenciaContatoTelefone,
				setPreferenciaContatoTelefone,
				preferenciaContatoEmail,
				setPreferenciaContatoEmail
			}}
		>
			{children}
		</Context.Provider>
	);
};
