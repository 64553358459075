import styled, { css } from 'styled-components';
import IntlCurrencyInput from 'react-intl-currency-input';

export const Wrapper = styled.div`
	width: 100%;
	margin: 0 auto;
`;

export const InputWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	max-width: 100%;
`;
export const Input = styled(IntlCurrencyInput)`
	${({ theme }) => css`
		font-size: 2.5rem;
		color: ${theme.colors.blue.weight};
		font-family: ${theme.font.family.superbold};
		text-align: center;
		max-width: calc(100% - 120px);

		margin: 0 3rem;
		border: 0;
		background: none;
	`}
`;
export const Button = styled.button`
	${({ theme }) => css`
		width: 3rem;
		height: 3rem;
		background: ${theme.colors.blue.pacificBlue};
		border-radius: 50%;
		border: 0;
		color: ${theme.colors.white};
		display: flex;
		align-items: center;
		justify-content: center;
	`}
`;
export const MinMaxWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	max-width: 100%;
	width: 100%;
`;
export const MinMaxInfo = styled.div`
	${({ theme }) => css`
		color: ${theme.colors.blue.weight};
		font-family: ${theme.font.family.light};
		font-size: 0.9rem;
		line-height: 1rem;
		margin-top: 1.6rem;
	`}
`;

export const Error = styled.div`
	${({ theme }) => css`
		font-size: ${theme.font.sizes.xsmall};
		color: ${theme.colors.blue.weight};
		margin-top: 1rem;
		text-align: center;
	`}
`;
