import { formatCurrency } from 'functions';
import * as Styled from './styles';

type CardSliderProps = {
	installments: number;
	value: number;
	interest?: number;
	active?: boolean;
	item?: any;
	onClick?: any;
};

const CardSlider = ({
	installments,
	interest,
	value,
	active = false,
	item,
	onClick
}: CardSliderProps) => (
	<Styled.Card onClick={() => onClick(item)} active={active}>
		<Styled.Installments>{`${installments} parcelas`}</Styled.Installments>
		<Styled.ValueInstallments>{formatCurrency(value)}</Styled.ValueInstallments>
		<Styled.Interest>{`Taxa de ${String(interest).replace(
			'.',
			','
		)}% a. m.`}</Styled.Interest>
	</Styled.Card>
);

export default CardSlider;
