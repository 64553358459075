import * as Styled from './styles';

import { ImSpinner2 } from 'react-icons/im';

type SpinnerProps = {
	color?: 'blue' | 'white';
};

const SpinnerLoading = ({ color = 'blue' }: SpinnerProps) => (
	<Styled.Wrapper color={color}>
		<ImSpinner2 size={20} />
	</Styled.Wrapper>
);

export default SpinnerLoading;
