import { useState } from 'react';
import useMoney from 'context/hooks/useMoney';
import * as Styled from './styles';

type TextFieldProps = {
	helperText?: string;
	label?: string;
	id: string;
	name: string;
	value: number;
	min?: number;
	max?: number;
	error?: string;
	onChange: (value: number) => void;
};

const TextFieldMoney = ({
	id,
	helperText,
	label,
	name,
	value,
	max = 150000,
	min = 5000,
	error,
	onChange
}: TextFieldProps) => {
	const defaultConfig = {
		locale: 'pt-BR',
		formats: {
			number: {
				BRL: {
					style: 'currency',
					currency: 'BRL',
					minimumFractionDigits: 0,
					maximumFractionDigits: 0
				}
			}
		}
	};

	const handleChange = (ev: any, value: number) => {
		!!onChange && onChange(value);
	};

	return (
		<Styled.Container>
			{helperText !== undefined && !error && (
				<Styled.HelperText>{helperText}</Styled.HelperText>
			)}
			{!!error && (
				<Styled.HelperText error={!!error}>{error}</Styled.HelperText>
			)}
			<Styled.InputCurrency
				currency="BRL"
				id={id}
				max={max}
				namme={name}
				config={defaultConfig}
				value={value}
				onChange={handleChange}
			/>
			{label !== undefined && <Styled.Label htmlFor={id}>{label}</Styled.Label>}
		</Styled.Container>
	);
};

export default TextFieldMoney;
