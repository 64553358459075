import styled, { css } from 'styled-components';

export const Wrapper = styled.div``;

export const Contents = styled.div`
	width: 100%;
	padding: 2.3rem 4rem;
`;

export const ImageContainer = styled.div`
	width: 100%;
	margin-top: 3.2rem;

	display: flex;
	align-items: center;
	justify-content: center;
`;

export const TextContents = styled.div`
	${({ theme }) => css`
		margin-top: 3.2rem;

		text-align: center;
		line-height: 1.38;

		font-family: ${theme.font.family.regular}, sans-serif;
		font-size: ${theme.font.sizes.medium};
		font-weight: 400;

		color: ${theme.colors.blue.weight};
	`}
`;

export const ButtonContainer = styled.div`
	${({ theme }) => css`
		margin-top: ${theme.spacings.large};

		button {
			margin: 0 auto;

			width: 22.9rem;

			font-size: 1.5rem;
			font-weight: 500;
		}
	`}
`;
