import * as Styled from './styles';
import error from 'assets/icons/error.svg';

type VehicleNotFoundProps = {
	title: string;
	contentText: string;
};

const VehicleNotFound = ({ title, contentText }: VehicleNotFoundProps) => {
	return (
		<Styled.Wrapper>
			<Styled.Header>
				{/* <p>Ops! Verificação incompleta</p> */}
				<p>{title}</p>
				<img src={error} />
			</Styled.Header>
			<Styled.Contents>
				{contentText}
				{/* <p>
					Utilize o botão <strong>&quot;Não lembro minha placa&quot;</strong> e
					insira manualmente suas informações para continuar a simulação
				</p> */}
			</Styled.Contents>
		</Styled.Wrapper>
	);
};

export default VehicleNotFound;
