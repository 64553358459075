import styled, { css } from 'styled-components';

export const Modal = styled.div`
	${({ theme }) => css`
		padding: 4.5rem ${theme.spacings.medium};
		background: ${theme.colors.white};
		width: 100%;
		border-radius: 1rem;
		max-width: 40rem;
	`}
`;
export const Heading = styled.h3`
	${({ theme }) => css`
		font-size: ${theme.font.sizes.medium};
		line-height: 1.56;
		color: ${theme.colors.blue.weight};
		text-align: center;
		margin-bottom: 4.5rem;
	`}
`;
export const Paragraph = styled.p`
	${({ theme }) => css`
		font-size: ${theme.font.sizes.medium};
		line-height: 1.56;
		color: ${theme.colors.blue.weight};
		text-align: center;
		margin-bottom: 4.5rem;
	`}
`;
export const Overlay = styled.div<{ open: boolean }>`
	${({ open, theme }) => css`
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgba(0, 0, 0, 0.8);
		opacity: ${open ? '1' : '0'};
		transition: opacity 0.3s ease-in-out;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 4rem;
		pointer-events: ${open ? 'all' : 'none'};
		z-index: ${theme.layers.alwaysOnTop};

		${Modal} {
			transform: ${open ? 'scale(1)' : 'scale(0.7)'};
			transition: all 0.3s ease-in-out;
		}
	`}
`;
