import * as Styled from './styles';
import checked from 'assets/icons/check-green.svg';

type VehicleFoundProps = {
	brand: string;
	model: string;
	yearManufacture: number;
	yearModel: number;
};

const VehicleFound = ({
	brand,
	model,
	yearModel,
	yearManufacture
}: VehicleFoundProps) => {
	return (
		<Styled.Wrapper>
			<Styled.Header>
				<p>Placa verificada com sucesso!</p>
				<img src={checked} />
			</Styled.Header>
			<Styled.Contents>
				<Styled.RowContent>
					<Styled.Label>Marca: </Styled.Label>
					<Styled.Value>{brand}</Styled.Value>
				</Styled.RowContent>
				<Styled.RowContent>
					<Styled.Label>Modelo: </Styled.Label>
					<Styled.Value>{model}</Styled.Value>
				</Styled.RowContent>
				<Styled.RowContent>
					<Styled.Label>Ano fabricação:</Styled.Label>
					<Styled.Value>{yearManufacture}</Styled.Value>
				</Styled.RowContent>
				<Styled.RowContent>
					<Styled.Label>Ano modelo: </Styled.Label>
					<Styled.Value>{yearModel}</Styled.Value>
				</Styled.RowContent>
			</Styled.Contents>
		</Styled.Wrapper>
	);
};

export default VehicleFound;
