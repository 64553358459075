import { UserDataContext } from './../providers/UserDataProvider';
import { useContext } from 'react';

const useUserData = () => {
	const context = useContext(UserDataContext);

	if (!context)
		throw new Error('useUserData must be used with UserDataContext');

	return context;
};

export default useUserData;
