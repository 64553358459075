import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
	padding-top: 3rem;
`;

export const MainContainer = styled.div`
	${({ theme }) => css`
		padding: 0 1.5rem;

		${theme.media.queryDesktop('1000')} {
			max-width: 50%;
			${theme.utils.marginCenter()}
		}
	`}
`;

export const ContainerPage = styled.div`
	${({ theme }) => css`
		margin-top: 2.5rem;

		.iFrameContainer {
			width: 100%;
			margin-bottom: 1.6rem;

			iframe {
				width: 100%;
				height: 400px;

				@media (max-width: 768px) {
					height: 300px;
				}

				@media (max-width: 468px) {
					height: 200px;
				}
			}
		}

		> h1 {
			margin-left: 1.9rem;
			font-family: ${theme.font.family.regular};
			font-size: 2.1rem;
			line-height: 3.1rem;

			color: ${theme.colors.blue.weight};

			strong {
				font-family: ${theme.font.family.bold};
				font-size: 2.5rem;
			}
		}
	`}
`;

export const ButtonContainer = styled.div`
	width: 100%;
	margin-top: 2.5rem;

	display: inline-flex;
	justify-content: center;

	button {
		width: auto;
		padding: 1.1rem 5.6rem;
	}
`;

export const TextContainer = styled.div`
	${({ theme }) => css`
		width: 100%;
		margin-top: 2.5rem;

		display: flex;
		flex-direction: column;
		align-items: center;
		color: ${theme.colors.blue.weight};

		> strong {
			font-family: ${theme.font.family.bold};
			font-size: 2.1rem;
		}

		> span {
			margin-top: 0.5rem;
			font-family: ${theme.font.family.regular};
			font-size: ${theme.font.sizes.xsmall};
		}
	`}
`;
