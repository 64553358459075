import apiFinancial from 'api/api-financial/api';
import financialServices from 'api/api-financial/services';
import shopkeeperServices from 'api/api-shopkeeper/services';
import useAnalytics from 'context/hooks/useAnalytics';
import {
	ContextProps,
	CurrrentInstallment,
	InstallmentsOptions,
	IOFResponse,
	ProviderProps,
	ResponseInstallmentsApi
} from 'context/models/money';
import { formatCurrency } from 'functions';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { createContext, useState } from 'react';
import { analyticsEvent } from 'service/analytics';

export const MoneyContext = createContext<ContextProps>({} as ContextProps);

const MoneyProvider = ({ children }: ProviderProps) => {
	const [carPrice, setCarPrice] = useState<string | number>(5000);
	const [userValue, setUserValue] = useState<string | number>(5000);
	const [finalValue, setFinalValue] = useState<string | number | undefined>();
	const [currentInstallment, setCurrentInstallments] =
		useState<CurrrentInstallment>({} as CurrrentInstallment);
	const [installments, setInstallments] = useState<InstallmentsOptions[]>([]);
	const [installmentsErrors, setInstallmentsErrors] = useState('');
	const [installmentsLoading, setInstallmentsLoading] = useState(false);

	const { analyticsValue, handleAddAnalyticsValue } = useAnalytics();

	const clearMoneyValues = () => {
		setCarPrice(5000);
		setFinalValue(undefined);
		setCurrentInstallments({} as CurrrentInstallment);
		setInstallments([]);
		setInstallmentsErrors('');
		setInstallmentsLoading(false);
	};

	const getInstallments = useCallback(
		async (carInformations, year) => {
			try {
				setInstallmentsLoading(true);
				if (finalValue && finalValue !== 'NaN') {
					const body = {
						codigoFipe: carInformations.fipeCode || null,
						valorFipe: carInformations.originalPrice || null,
						codAno: carInformations.yearModel || year,
						valorEmprestimo: Number(finalValue)
					};

					const response =
						await financialServices.getInstallments<ResponseInstallmentsApi>(
							body
						);

					if (typeof response === 'string') {
						throw new Error('Tem erro no back');
					}

					setInstallmentsErrors('');

					const prazos = response.data.tabelas.map(item => {
						const prazos = item.prazos.map(prazo => ({
							installments: prazo.prazo,
							value:
								(body.valorEmprestimo +
									item.valorGravame +
									item.valorTaxaCadastro) *
								prazo.valorMultiplicador,
							multiplecateValue: prazo.valorMultiplicador,
							gravameValue: item.valorGravame,
							rateRegister: item.valorTaxaCadastro,
							seqTabelaFinanceira: item.seqTabelaFinanceira,
							pessoaFinanceiraId: item.pessoaFinanceiraId,
							interest: prazo.perecentualJuros
						}));

						return prazos;
					});

					const newArray = prazos[0].map(item => ({
						...item
					}));

					setInstallments(newArray);
				}
				setInstallmentsLoading(false);
			} catch (err) {
				analyticsEvent('ck_tabela_não_encontrada', analyticsValue);

				setInstallmentsLoading(false);
				setInstallmentsErrors('Ta com guambiarra no back! se vira');
			}
		},
		[finalValue]
	);

	const installmentsChange = useCallback(() => {
		setInstallments(oldState => {
			return oldState.map(item => ({
				...item,
				value:
					(Number(finalValue) + item.gravameValue + item.rateRegister) *
					item.multiplecateValue
			}));
		});
	}, [finalValue]);

	const getCurrentInstallmentValue = useCallback(
		async (currentOption: number) => {
			const option = installments[currentOption];

			const response = await shopkeeperServices.getIof<IOFResponse>({
				valorTotal: Number(finalValue),
				prazo: option.installments,
				seqTabelaFinanceira: option.seqTabelaFinanceira,
				pessoaFinanceiraId: option.pessoaFinanceiraId
			});

			const analyticsBody = {
				...analyticsValue,
				parcela: option?.installments,
				valorParcela: Math.floor(option?.value)
			};

			analyticsEvent('ck_mudar_parcela', analyticsBody);

			handleAddAnalyticsValue(analyticsBody);

			setCurrentInstallments({
				value: formatCurrency(option?.value).replace('R$', ''),
				instalment: option?.installments,
				gravameValue: option?.gravameValue,
				rateRegister: option?.rateRegister,
				seqTabelaFinanceira: option.seqTabelaFinanceira,
				valorIof: Number(response.data.valorIof),
				percentualCet: response.data.percentualCet
			});
		},
		[installments]
	);

	useEffect(() => {
		installmentsChange();
	}, [carPrice, installmentsChange]);

	const updateUserValue = useCallback((value: number | string) => {
		setUserValue(value);
	}, []);

	const updateCarPrice = useCallback((value: number | string) => {
		setCarPrice(value);
	}, []);
	const updateFinalValue = useCallback((value: number | string | undefined) => {
		setFinalValue(Number(value).toFixed());
	}, []);

	const clearInstallmentOption = () => {
		setCurrentInstallments({} as CurrrentInstallment);
		setInstallments([]);
	};

	return (
		<MoneyContext.Provider
			value={{
				finalValue,
				updateFinalValue,
				carPrice,
				userValue,
				updateCarPrice,
				installments,
				currentInstallment,
				getCurrentInstallmentValue,
				getInstallments,
				updateUserValue,
				installmentsErrors,
				clearInstallmentOption,
				installmentsLoading,
				clearMoneyValues
			}}
		>
			{children}
		</MoneyContext.Provider>
	);
};

export default MoneyProvider;
