import styled, { css } from 'styled-components';

export const Card = styled.div`
	${({ theme }) => css`
		width: 100%;
		margin-top: 2rem;
		padding: 2.8rem 2.5rem 2.8rem 2.5rem;

		background-color: ${theme.colors.blue.outlineBlue};

		border-radius: 1.5rem;

		> p {
			color: ${theme.colors.blue.weight};

			font-family: ${theme.font.family.light};
			font-size: 1.6rem;
			line-height: 2.3rem;

			& + p {
				margin-top: 2.8rem;
			}
		}
	`}
`;

export const Title = styled.div`
	${({ theme }) => css`
		display: flex;
		justify-content: center;

		margin-bottom: 2.8rem;

		> span {
			margin-left: 1rem;

			display: inline-flex;
			flex-direction: column;
			justify-content: flex-end;

			font-family: ${theme.font.family.regular};
			line-height: 0.9;

			color: ${theme.colors.blue.weight};
		}

		strong {
			font-family: ${theme.font.family.bold};
			font-size: 3.9rem;
			color: ${theme.colors.blue.weight};
		}
	`}
`;
