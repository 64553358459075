import styled, { css } from 'styled-components';
import * as CheckStyles from 'components/Checkbox/styles';
import * as ButtonsStyled from 'components/Buttons/styles';

export const Wrapper = styled.div`
	padding-bottom: 2.5rem;
`;
export const InputWrapper = styled.div`
	${({ theme }) => css`
		padding: ${theme.spacings.small} 1rem;
		background: ${theme.colors.gray};
		border-radius: 0.5rem;
		margin-top: 1.6rem;
	`}
`;
export const Content = styled.div<{ customPadding?: string }>`
	${({ theme, customPadding }) => css`
		${customPadding
			? css`
					padding: ${customPadding};
			  `
			: css`
					padding: 2.6rem 4rem 4rem;
			  `}

		${theme.media.queryDesktop('1000')} {
			width: 50rem;
			margin: 0 auto;
		}
	`}
`;
export const Paragraph = styled.p`
	${({ theme }) => css`
		color: ${theme.colors.blue.weight};
		line-height: 1.44;
		margin-top: 1.65rem;
	`}
`;

export const Form = styled.form`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
`;

export const CalculateButton = styled.button`
	${({ theme }) => css`
		background: ${theme.colors.blue.pacificBlue};
		border: 0;
		height: 3rem;
		padding: 0 2.4rem;
		font-size: ${theme.font.sizes.xsmall};
		font-family: ${theme.font.family.medium};
		color: ${theme.colors.white};
		margin-left: 0.2rem;
		border-bottom-right-radius: 0.5rem;
		border-bottom-left-radius: 0.5rem;
	`}
`;
export const ButtonsWrapper = styled.div`
	${({ theme }) => css`
		display: flex;
		padding: 0 4rem;
		margin-top: 3rem;

		${ButtonsStyled.Button}:nth-child(2) {
			font-size: 1.5rem;
			font-weight: 500;

			svg {
				margin-left: 1rem;
			}
		}
		${ButtonsStyled.Button}:first-child {
			width: 7rem;
			margin-right: 1.2rem;
		}

		${theme.media.queryDesktop('1000')} {
			width: 50rem;
			margin: 3rem auto 0;
		}
	`}
`;

export const Table = styled.div`
	${({ theme }) => css`
		background: ${theme.colors.gray};
		padding: ${theme.spacings.medium};
		border-radius: 0.5rem;
	`}
`;
export const TableRow = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 0.3rem;
	&:not(:last-child) {
		border-bottom: 1px solid #e8e9ee;
		margin-bottom: 0.6rem;
	}

	&:last-child {
		padding-top: 2rem;
	}
`;
export const Key = styled.div`
	${({ theme }) => css`
		color: ${theme.colors.blue.weight};
		font-size: ${theme.font.sizes.xsmall};

		span.total {
			font-family: ${theme.font.family.bold};
			padding: 2rem 0;
			display: block;
		}

		span.key-installments_1,
		span.key-installments_2 {
			color: ${theme.colors.blue.weight};
			font-family: ${theme.font.family.bold};
		}

		span.key-installments_2 {
			font-size: ${theme.font.sizes.medium};
		}
	`}
`;
export const Value = styled.div`
	${({ theme }) => css`
		color: ${theme.colors.blue.weight};
		font-size: ${theme.font.sizes.xsmall};
		font-family: ${theme.font.family.bold};
		width: 8rem;
		display: flex;
		align-items: center;
		justify-content: space-between;

		span.value {
			font-size: ${theme.font.sizes.medium};
		}
	`}
`;

export const Cet = styled.span`
	${({ theme }) => css`
		font-size: 1rem;
		color: ${theme.colors.blue.weight};
		margin-top: 3rem;
	`}
`;

export const SubjectToAnalysis = styled.p`
	${({ theme }) => css`
		font-size: 1.1rem;
		color: ${theme.colors.blue.weight};
		margin-top: 1.2rem;
	`}
`;

export const AcceptedCredit = styled.div`
	${({ theme }) => css`
		width: 100%;
		font-size: ${theme.font.sizes.xsmall};
		margin-top: 5rem;
		margin-bottom: 3rem;
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;

		${CheckStyles.Wrapper}:not(:first-child) {
			margin-top: 3rem;
		}
	`}
`;

export const Download = styled.div`
	${({ theme }) => css`
		text-align: center;
		text-decoration: underline;
		color: ${theme.colors.blue.light};
		font-size: ${theme.font.sizes.xsmall};
	`}
`;
