import styled, { css, DefaultTheme } from 'styled-components';

const headingModfiers = {
	normal: (theme: DefaultTheme) => css`
		font-size: ${theme.font.sizes.small};
	`,
	large: (theme: DefaultTheme) => css`
		font-size: ${theme.font.sizes.medium};
		&::after {
			margin-top: 1.3rem;
			height: 0.2rem;
		}
	`
};

type HeadingProps = {
	size?: 'normal' | 'large';
	headingColor: 'primary' | 'secondary';
};

export const Heading = styled.h3<HeadingProps>`
	${({ theme, size, headingColor }) => css`
		color: ${headingColor === 'primary'
			? theme.colors.blue.weight
			: theme.colors.blue.weightLight};

		.align {
			display: flex;
			align-items: center;
		}

		svg {
			margin-right: 1rem;
			color: ${theme.colors.blue.weightLight};
		}

		&::after {
			content: '';
			display: block;
			margin-top: 0.65rem;
			height: 0.1rem;
			background: ${theme.colors.blue.weightLight};
			width: 100%;
		}

		${!!size && headingModfiers[size](theme)}
	`}
`;
