import styled, { css, DefaultTheme } from 'styled-components';
import IntlCurrencyInput from 'react-intl-currency-input';

type GradientProps = {
	backgroundActive: number | string;
	theme: DefaultTheme;
};

const gradient = ({ theme, backgroundActive }: GradientProps) => {
	return `linear-gradient(90deg, ${theme.colors.blue.weight} ${backgroundActive}%, ${theme.colors.blue.weightLight} ${backgroundActive}%)`;
};

export const InputWrapper = styled.div``;
export const InputRange = styled.input<{ backgroundActive: number | string }>`
	${({ theme, backgroundActive }) => css`
		appearance: none;
		width: 100%;
		background: ${gradient({ theme, backgroundActive })};
		border-radius: 1rem;
		height: 0.5rem;

		&::-webkit-slider-thumb {
			appearance: none;
			width: 2.4rem;
			height: 2.4rem;
			background: ${theme.colors.blue.weight};
			border-radius: 50%;
			cursor: pointer;
		}

		&::-moz-range-thumb {
			appearance: none;
			width: 2.4rem;
			height: 2.4rem;
			background: ${theme.colors.blue.weight};
			border-radius: 50%;
			cursor: pointer;
		}
	`}
`;

export const InputTextWrapper = styled.div`
	display: flex;
	padding-bottom: 2.5rem;
	justify-content: center;
	flex-direction: column;
	${({ theme }) => css`
		span {
			font-size: ${theme.font.sizes.xsmall};
			color: ${theme.colors.blue.weight};
			margin-top: 1rem;
			text-align: center;
		}
	`}
`;
export const InputText = styled(IntlCurrencyInput)`
	${({ theme }) => css`
		color: ${theme.colors.green};
		font-family: ${theme.font.family.bold};
		appearance: none;
		font-size: 4rem;
		border: 0;
		width: 100%;
		text-align: center;
		background: none;
	`}
`;

export const MinMaxWrapper = styled.div`
	${({ theme }) => css`
		margin-top: ${theme.spacings.xsmall};
		display: flex;
		justify-content: space-between;
		width: 100%;

		span {
			font-size: 1.3rem;
			color: ${theme.colors.blue.weight};
		}
	`}
`;
