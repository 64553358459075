import { initializeApp, getApps } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';

export const FirebaseConfig = {
	databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
	name: process.env.REACT_APP_FIREBASE_NAME
};

if (getApps().length === 0) {
	initializeApp(FirebaseConfig);
}

const analytics = getAnalytics();

export { analytics, logEvent };
