import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{ color: 'blue' | 'white' }>`
	${({ theme, color }) => css`
		color: ${color === 'blue'
			? theme.colors.blue.pacificBlue
			: theme.colors.white};
		height: 4rem;
		width: 100%;
		${theme.utils.flexCenter}

		svg {
			animation: spinner 0.5s ease-in infinite;

			@keyframes spinner {
				to {
					transform: rotate(1turn);
				}
			}
		}
	`}
`;
