import { createContext, useState } from 'react';

import {
	ContextProps,
	ProviderProps,
	AnalyticsObject
} from 'context/models/analytics';

export const AnalyticsContext = createContext<ContextProps>({} as ContextProps);

const AnalyticsProvider = ({ children }: ProviderProps) => {
	const [params, setParams] = useState({
		utm_source: '',
		utm_medium: '',
		utm_campaign: ''
	});

	const [analyticsValue, setAnalyticsValue] = useState({} as AnalyticsObject);

	const handleAddAnalyticsValue = (objectValue: AnalyticsObject) => {
		setAnalyticsValue(oldState => ({ ...oldState, ...objectValue }));
	};

	return (
		<AnalyticsContext.Provider
			value={{ params, setParams, handleAddAnalyticsValue, analyticsValue }}
		>
			{children}
		</AnalyticsContext.Provider>
	);
};

export default AnalyticsProvider;
