import MoneyProvider from './providers/MoneyProvider';
import AnalyticsProvider from './providers/AnalyticsProvider';
import StepsProvider from './providers/StepsProvider';
import UserDataProvider from './providers/UserDataProvider';
import VehiculeProvider from './providers/VehiculeProvider';

type AppProviderProps = {
	children: React.ReactNode;
};

const AppProvider = ({ children }: AppProviderProps) => (
	<AnalyticsProvider>
		<StepsProvider>
			<UserDataProvider>
				<MoneyProvider>
					<VehiculeProvider>{children}</VehiculeProvider>
				</MoneyProvider>
			</UserDataProvider>
		</StepsProvider>
	</AnalyticsProvider>
);

export default AppProvider;
