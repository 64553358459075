import styled, { css } from 'styled-components';

export const Checkbox = styled.input`
	${({ theme }) => css`
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		appearance: none;
		width: 1.8rem;
		height: 1.8rem;
		border: 0.1rem solid ${theme.colors.blue.weight};
		border-radius: 0.5rem;
		transition: background border 0.3s;
		position: relative;
		outline: none;
		&:before {
			content: '';
			width: 0.4rem;
			height: 0.7rem;
			border: 0.2rem solid ${theme.colors.white};
			border-top: 0;
			border-left: 0;
			transform: rotate(45deg);
			position: absolute;
			top: 0.2rem;
			opacity: 0;
			transition: 0.3s;
		}

		&:checked {
			border-color: ${theme.colors.blue.weight};
			background: ${theme.colors.blue.weight};
			&:before {
				opacity: 1;
			}
		}
	`}
`;
export const Label = styled.label`
	${({ theme }) => css`
		display: flex;
		align-items: center;
		color: ${theme.colors.blue.weight};
		margin-left: 1rem;
		font-size: ${theme.font.sizes.xsmall};
		span.green {
			color: ${theme.colors.green};

			svg {
				margin-right: 0.5rem;
			}
		}

		span {
			display: flex;
			align-items: center;
			img {
				width: 1.6rem;
				margin-right: 0.5rem;
			}
		}
	`}
`;

export const Wrapper = styled.div<{ column: boolean }>`
	display: flex;

	${({ column }) =>
		column &&
		css`
			flex-direction: column;
			justify-content: center;
			align-items: center;

			${Label} {
				margin-top: 0.8rem;
				margin-left: 0;
			}
		`}
`;
