import React, { InputHTMLAttributes, useState } from 'react';
import { formatCurrency } from 'functions';
import * as Styled from './styles';

type InputRangeProps = {
	value: string | number;
	id: string;
	name: string;
	max?: number;
	min?: number;
	initialValue?: number;
	error: string;
	onValue: (value: string | number) => void;
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>;

const InputMoney = ({
	value,
	id,
	name,
	max = 70000,
	onValue,
	error,
	initialValue = 0,
	...rest
}: InputRangeProps) => {
	const currencyConfig = {
		locale: 'pt-BR',
		formats: {
			number: {
				BRL: {
					style: 'currency',
					currency: 'BRL',
					minimumFractionDigits: 0,
					maximumFractionDigits: 0
				}
			}
		}
	};

	const handleInputChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		value: number
	) => {
		onValue(value === 0 ? '' : value);
	};

	const handleValueInputRange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		onValue(event.target.value);
	};

	return (
		<Styled.InputWrapper>
			<Styled.InputTextWrapper>
				<Styled.InputText
					id={id}
					name={name}
					max={max}
					min={initialValue}
					value={value}
					config={currencyConfig}
					currency="BRL"
					onChange={handleInputChange}
				/>
				{!!error && <span>{error}</span>}
			</Styled.InputTextWrapper>
			<Styled.InputRange
				step="1000"
				min={initialValue}
				max={max}
				id={id}
				name={name}
				type="range"
				backgroundActive={
					max ? `${(Number(value) / Number(max)) * 100}` : value
				}
				onChange={handleValueInputRange}
				value={value}
				{...rest}
			/>
			<Styled.MinMaxWrapper>
				<span>{formatCurrency(Number(initialValue))}</span>
				<span>{formatCurrency(Number(max))}</span>
			</Styled.MinMaxWrapper>
		</Styled.InputWrapper>
	);
};

export default InputMoney;
