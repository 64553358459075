import { createGlobalStyle, css } from 'styled-components';

export default createGlobalStyle`

	@font-face {
		font-family: 'roboto-regular';
		font-display: swap;
		font-weight: 400;
		src: local('Roboto-Regular'), url('fonts/Roboto-Regular.ttf');
	}

	@font-face {
		font-family: 'roboto-light';
		font-display: swap;
		src: local('Roboto-Light'), url('fonts/Roboto-Light.ttf');
	}

	@font-face {
		font-family: 'roboto-bold';
		font-display: swap;
		src: local('Roboto-bold'), url('fonts/Roboto-Bold.ttf');
	}

	@font-face {
		font-family: 'roboto-medium';
		font-display: swap;
		src: local('Roboto-Medium'), url('fonts/Roboto-Medium.ttf');
	}

	@font-face {
		font-family: 'roboto-superbold';
		font-display: swap;
		font-weight: 900;
		src: local('Roboto-Black'), url('fonts/Roboto-Black.ttf');
	}

	html {
		font-size: 62.5%;
		scroll-behavior: smooth;
	}

	input {
		-webkit-text-size-adjust: 150%;
	}

	* {
		padding: 0;
		margin: 0;
		box-sizing: border-box;
		outline: 0;
	}

	#root, body {
		${({ theme }) => css`
			height: 100vh;
			font-family: ${theme.font.family.regular};
			font-size: ${theme.font.sizes.medium};
		`}
	}

	button {
		cursor: pointer;
	}
`;
