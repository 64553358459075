import manIcon from 'assets/icons/manIcon.png';
import manVariantIcon from 'assets/icons/man-variant.png';
import womanIcon from 'assets/icons/womanIcon.png';

export default [
	{
		comment: `"As taxas dos outros bancos estavam muito altas, a Sinosserra me ajudou muito!"`,
		author: 'Ricardo Dias Moreira | Porto Alegre - RS',
		image: manVariantIcon
	},
	{
		comment: `"Essa financeira é muito confiável e honesta. Recomendo a todos"`,
		author: 'Cesar Piletti | Bento Gonçalves - RS',
		image: manIcon
	},
	{
		comment: `"Sinosserra Financeira é a solução para quem precisa sempre profissional capacitados e atendimento de melhor qualidade no mercado! Eu estou maravilhada com essa empresa, só tenho a agradecer de coração ❤️"`,
		author: 'Miria Nunes Paim | Alegrete - RS',
		image: womanIcon
	}
];
