import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as CTX from './globalContext/globalContext';

ReactDOM.render(
	<React.StrictMode>
		<CTX.Provider>
			<App />
		</CTX.Provider>
	</React.StrictMode>,
	document.getElementById('root')
);
