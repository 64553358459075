import apiShopKeeper from './api';

const RESOURCE = '/rest';

export type GetIof = {
	pessoaFinanceiraId: number;
	seqTabelaFinanceira: number;
	prazo: number;
	valorTotal: number;
};

const getLicensePlate = async <T>(licensePlate: string): Promise<T> => {
	const response = await apiShopKeeper.get<T>(
		`${RESOURCE}/veiculo/${licensePlate}`
	);

	return response.data;
};

const getIof = async <T>(data: GetIof): Promise<T> => {
	const response = await apiShopKeeper.post<T>(
		`${RESOURCE}/financiamento/cet/buscar`,
		data
	);

	return response.data;
};

const shopkeeperServices = {
	getLicensePlate,
	getIof
};

export default shopkeeperServices;
