import Button from 'components/Buttons';
import { createPortal } from 'react-dom';
import * as Styled from './styles';

type ModalProps = {
	open: boolean;
	closeModal: () => void;
	title: string;
	contentText: string;
};

const ModalElement = ({ closeModal, open, title, contentText }: ModalProps) => {
	return (
		<Styled.Overlay open={open}>
			<Styled.Modal>
				<Styled.Heading>{title}</Styled.Heading>
				<Styled.Paragraph>{contentText}</Styled.Paragraph>
				<Button onClick={closeModal}>Ok, entendi!</Button>
			</Styled.Modal>
		</Styled.Overlay>
	);
};

const Modal = ({ closeModal, open, contentText, title }: ModalProps) => {
	return createPortal(
		<ModalElement
			contentText={contentText}
			title={title}
			closeModal={closeModal}
			open={open}
		/>,
		document.getElementById('modal')!
	);
};

export default Modal;
