import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
	${({ theme }) => css`
		max-width: 36rem;
		margin: 0 auto;

		.slick-list .slick-slide {
			width: 180px !important;
		}

		${theme.media.query('2560')} {
			max-width: 36rem;
		}

		${theme.media.query('1920')} {
			max-width: 36rem;
		}

		${theme.media.query(theme.breakpoints.xl)} {
			max-width: 36rem;
		}

		${theme.media.query('450')} {
			max-width: 41rem;
		}

		${theme.media.query(theme.breakpoints.md)} {
			max-width: 76rem;
		}
	`}
`;
