import { AnalyticsContext } from '../providers/AnalyticsProvider';
import { useContext } from 'react';

const useAnalytics = () => {
	const context = useContext(AnalyticsContext);

	if (!context) throw new Error('useAnalytics must be used with MoneyContext');

	return context;
};

export default useAnalytics;
