import Header from 'components/Header';
import Heading from 'components/Heading';

import endImage from 'assets/end_image.svg';

import * as Styled from './styles';
import useSteps from 'context/hooks/useSteps';
import useUserData from 'context/hooks/useUserData';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

interface stateType {
	redirectModal?: boolean;
	userName?: string;
}

const RequestedAnalyisis = () => {
	const { isCompletedState } = useSteps();

	const { userData } = useUserData();
	const { state } = useLocation<stateType>();
	const [dataRedirectModal, setDataRedirectModal] = useState<stateType>({
		redirectModal: false,
		userName: ''
	});

	useEffect(() => {
		if (!isCompletedState(3)) {
			window.location.href = '/';
		}
	}, [isCompletedState]);

	useEffect(() => {
		setDataRedirectModal({
			redirectModal: state?.redirectModal,
			userName: state?.userName
		});
	}, [state]);

	return (
		<Styled.Wrapper>
			<Header headerSize="large" nonSteps={dataRedirectModal.redirectModal} />
			<Styled.Contents>
				<Heading>Análise solicitada</Heading>
				<Styled.ImageContainer>
					<img src={endImage} />
				</Styled.ImageContainer>
				<Styled.TextContents>
					<p>
						{!dataRedirectModal.redirectModal
							? userData?.fullname?.toString().split(' ')[0]
							: dataRedirectModal?.userName?.toString().split(' ')[0]}
						, <br />
						sua {!dataRedirectModal.redirectModal
							? 'simulação'
							: 'solicitação'}{' '}
						foi enviada com sucesso!
					</p>
					<p>
						<br />
						A Sinosserra Financeira agradece imensamente por escolher nosso
						serviço!
						<br />
						Estamos comprometidos em oferecer a você a melhor experiência
						possível e ajudar a transformar seus planos em realidade.
						<br />
						Fique atento ao seu telefone nas próximas 24 horas úteis, pois um
						dos nossos atendentes entrará em contato para dar continuidade ao
						seu atendimento.
					</p>
				</Styled.TextContents>
			</Styled.Contents>
		</Styled.Wrapper>
	);
};

export default RequestedAnalyisis;
