import api from './api';
// import { GetInstallmentsProps } from './models';

const RESOURCE = 'correios/endereco';

interface ResponsePost {
	codigoFipe: string;
	codAno: number;
	precoFipe: number;
	valorEmprestimo: number | string;
	seqTabelaFinanceira: number;
	prazo: number;
	valorParcela: number | string;
	cpf: string;
	nomeCliente: string | boolean;
	placa: string;
	email: string | boolean;
	telefone: string;
	ddd: string;
	usuario: string;
}

const getCEP = async <T>(CEP: string): Promise<T> => {
	const response = await api.get<T>(`${RESOURCE}/${CEP}`);
	return response.data;
};

const getTipoOcupacao = async <T>(): Promise<T> => {
	const response = await api.get<T>(`dominio?dominio=TIPO_OCUPACAO`);
	return response.data;
};

const postEmp = async <T>(body: any): Promise<T> => {
	const response = await api.post<T>(`financeira/cp/atendimentoCP/`, body);
	return response.data;
};

const appdsvServices = {
	getCEP,
	postEmp,
	getTipoOcupacao
};

export default appdsvServices;
