import checked from 'assets/icons/check-green.svg';
import error from 'assets/icons/error.svg';
import React from 'react';
import * as Styled from './styles';

interface Props {
	street: string;
	city: string;
	UF: string;
	check: boolean;
}

const FoundedCPF = ({ street, city, UF, check }: Props) => {
	return (
		<React.Fragment>
			{check && (
				<Styled.Wrapper>
					{!!UF && !!city && (
						<React.Fragment>
							<Styled.TextContents>
								<p>{street}</p>
								<p>
									{city}, {UF}
								</p>
							</Styled.TextContents>
							<img src={checked} />
						</React.Fragment>
					)}
				</Styled.Wrapper>
			)}
			{!!UF && !check && (
				<Styled.Wrapper>
					<React.Fragment>
						<Styled.TextContents>
							<p className="error">CEP INVÀLIDO</p>
						</Styled.TextContents>
						<img src={error} />
					</React.Fragment>
				</Styled.Wrapper>
			)}
		</React.Fragment>
	);
};

export default FoundedCPF;
