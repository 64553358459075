export default {
	breakpoints: {
		sm: '360',
		md: '414',
		xl: '768'
	},

	font: {
		family: {
			regular: 'roboto-regular',
			medium: 'roboto-medium',
			light: 'roboto-light',
			bold: 'roboto-bold',
			superbold: 'roboto-superbold'
		},

		sizes: {
			xsmall: '1.2rem',
			small: '1.4rem',
			medium: '1.6rem',
			large: '1.8rem',
			xlarge: '2.0rem',
			xxlarge: '2.8rem'
		}
	},

	colors: {
		blue: {
			weight: '#012d5c',
			superLight: '#94d2e8',
			light: '#61b9d3',
			weightLight: '#5eb8d2',
			pacificBlue: '#00a2e0',
			outlineBlue: '#ddf0fc',
			disabled: 'rgba(1, 45, 92, 0.4)'
		},
		white: '#fff',
		green: '#32ba46',
		gray: '#f5f6fa',
		whiteGray: '#ececec',
		gradiant:
			'linear-gradient(to left, #94d2e8 132%, #61b9d3 53%, #5eb8d2 0%);',
		error: '#B00020'
	},

	spacings: {
		xxsmall: '0.8rem',
		xsmall: '1.6rem',
		small: '2rem',
		medium: '3rem',
		large: '3.8rem',
		xlarge: '4.6rem'
	},

	layers: {
		alwaysOnTop: 50,
		contact: 40,
		overlayClose: -1
	},

	utils: {
		fullWidthPositionAbsolute: () => `
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		`,

		flexCenter: () => `
			display: flex;
			justify-content: center;
			align-items: center;
		`,
		marginCenter: () => `
			margin: 0 auto;
		`
	},

	media: {
		query: (breakpoint: string) => `@media (max-width: ${breakpoint}px)`,
		queryDesktop: (breakpoint: string) => `@media (min-width: ${breakpoint}px)`,
		queryDesktopHeight: (breakpoint: string) =>
			`@media (max-height: ${breakpoint}px)`
	}
} as const;
