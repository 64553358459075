import React, { useState, useEffect } from 'react';
import * as BsIcons from 'react-icons/bs';

import Modal from 'components/Modal';
import Header from 'components/Header';
import Button from 'components/Buttons';
import Heading from 'components/Heading';
import Loading from 'components/Loading';
import SelectField from 'components/SelectField';
import InputField from 'components/InputField';
import AutoComplete from 'components/AutoComplete';
import VehicleFound from './components/VehicleFound';
import VehicleNotFound from './components/VehicleNotFound';

import history from 'service/history';
import useSteps from 'context/hooks/useSteps';

import useVehicule from 'context/hooks/useVehicule';

type ResetCapsType = {
	resetTooPlate: 'yes' | 'no';
};

import * as Styled from './styles';
import { vehicleDataValidations } from 'utils/validations';

const VehicleData = () => {
	const {
		locations,
		brands,
		getCarYears,
		defineModelYear,
		getLicensePlate,
		carInformations,
		clearFields,
		setFieldsValue,
		inputView,
		isNotCarError,
		licenseResponseError,
		handleInputsDisabled,
		inputsDisabled,
		setInputsDisabled,
		licensePlate,
		inputValues,
		inputErrors,
		removeInputErrors,
		handleFoward,
		loadingLicence,
		autocompleteLoading
	} = useVehicule();
	const [open, setOpen] = useState(false);
	const { changeCompletedState, isCompletedState } = useSteps();
	const [areThereErrors, setAreThereErrors] = useState<boolean>(false);

	useEffect(() => {
		if (!isCompletedState(1)) {
			window.location.href = '/';
		}
	}, [isCompletedState]);

	useEffect(() => {
		const errors = vehicleDataValidations(inputValues);
		const areThereErrorsResult =
			Object.keys(errors).length > 0 ||
			Object.keys(inputErrors).length > 0 ||
			isNotCarError ||
			licenseResponseError;

		setAreThereErrors(areThereErrorsResult);
	}, [inputValues, inputErrors, isNotCarError, licenseResponseError]);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const initialValues: any = {
		location: locations,
		brand: brands
	};

	const handleInputChange = (
		inputName: string,
		value: string | boolean | number
	) => {
		switch (inputName) {
			case 'brand': {
				setFieldsValue(inputName, String(value));
				break;
			}
			case 'model': {
				setFieldsValue(inputName, String(value));
				getCarYears(String(value));
				break;
			}

			case 'modelYear': {
				setFieldsValue(inputName, String(value));
				defineModelYear(String(value));
				break;
			}

			case 'location': {
				setFieldsValue(inputName, String(value));
				break;
			}

			case 'license-plate': {
				setFieldsValue(inputName, String(value));
				break;
			}

			case 'paidoff': {
				setFieldsValue(inputName, value);
				break;
			}

			case 'valueParcel': {
				setFieldsValue(inputName, value);
				break;
			}

			case 'amountParcel': {
				setFieldsValue(inputName, value);
				break;
			}

			default:
		}

		removeInputErrors('paidoff');
		setFieldsValue('paidoff', true); // -- Sendo verificado no help-you, aqui deve ser sempre "true"

		removeInputErrors(inputName);
	};

	const clearAllCamps = ({ resetTooPlate }: ResetCapsType) => {
		if (resetTooPlate === 'yes') {
			handleInputChange('license-plate', '');
			handleInputChange('brand', '');
			handleInputChange('model', '');
			handleInputChange('modelYear', '');
			handleInputChange('location', '');
			handleInputChange('valueParcel', '');
			return handleInputChange('amountParcel', '');
		}

		handleInputChange('brand', '');
		handleInputChange('model', '');
		handleInputChange('modelYear', '');
		handleInputChange('location', '');
		handleInputChange('valueParcel', '');
		return handleInputChange('amountParcel', '');
	};

	const moveToBackPage = () => {
		changeCompletedState(2, false);
		clearAllCamps({ resetTooPlate: 'yes' });

		history.push({
			pathname: '/help-you',
			search: location.search
		});
	};

	const resetOtherAndGetPlate = (ev: React.ChangeEvent<HTMLInputElement>) => {
		if (ev.target.value.length > 0) {
			clearFields('license-plate');
			clearAllCamps({ resetTooPlate: 'no' });
			setInputsDisabled(true);
		}

		handleInputChange('license-plate', ev.target.value.toLocaleUpperCase());
	};

	return (
		<Styled.Wrapper>
			<Header headerSize="large" />
			<Styled.Contents>
				<Heading>Sobre seu veículo</Heading>

				<form onSubmit={getLicensePlate}>
					<Styled.GridInput>
						<InputField
							id="license-plate"
							name="license-plate"
							label="Placa"
							value={licensePlate}
							onChange={ev => resetOtherAndGetPlate(ev)}
						/>

						{!Object.values(carInformations).length && !licenseResponseError ? (
							<button type="submit" disabled={licensePlate ? false : true}>
								Consultar
							</button>
						) : (
							<button
								type="button"
								onClick={() => clearFields('license-plate')}
							>
								Limpar
							</button>
						)}

						<button type="button" onClick={handleInputsDisabled}>
							Não lembro a placa
						</button>
					</Styled.GridInput>
					<p>Inserir a placa e consulte para preenchimento automático.</p>
				</form>
				<div>
					<Styled.InputContainer>
						{inputView && (
							<React.Fragment>
								<AutoComplete
									error={inputErrors['brand']}
									isLoading={autocompleteLoading}
									id="brand"
									name="brand"
									label="Marca"
									inputValue={inputValues['brand']}
									options={initialValues['brand']}
									disabled={inputsDisabled}
									onValue={value => handleInputChange('brand', value)}
								/>

								<InputField
									disabled={inputsDisabled}
									error={inputErrors['model']}
									id="inputModel"
									name="model"
									label="Modelo"
									value={inputValues['model'] || ''}
									onChange={ev => handleInputChange('model', ev.target.value)}
								/>

								<InputField
									disabled={inputsDisabled}
									error={inputErrors['modelYear']}
									id="year"
									name="modelYear"
									label="Ano do modelo"
									value={inputValues['modelYear'] || ''}
									onChange={ev =>
										handleInputChange('modelYear', ev.target.value)
									}
								/>
							</React.Fragment>
						)}

						{loadingLicence && (
							<Loading message="Estamos verificando sua placa" />
						)}

						{!inputView &&
							!Object.values(carInformations).length &&
							!loadingLicence &&
							!isNotCarError &&
							licenseResponseError && (
								<VehicleNotFound
									title="Ops! Verificação incompleta."
									contentText='Utilize o botão "Não lembro minha placa" e
									insira manualmente suas informações para continuar a simulação.'
								/>
							)}
						{!inputView &&
							!Object.values(carInformations).length &&
							!loadingLicence &&
							isNotCarError &&
							licenseResponseError && (
								<VehicleNotFound
									title="Ops!"
									contentText='Informe a placa de um carro, ou utilize o botão "Não lembro minha placa" e
									insira manualmente suas informações para continuar a simulação'
								/>
							)}
						{!!Object.values(carInformations).length && !loadingLicence && (
							<VehicleFound
								yearModel={carInformations.yearModel}
								brand={carInformations.brand}
								model={carInformations.model}
								yearManufacture={carInformations.yearManufacture}
							/>
						)}
						<SelectField
							error={inputErrors['location']}
							id="location"
							name="location"
							label="Onde o veículo está emplacado?"
							value={inputValues.location}
							defaultValue={inputValues.location}
							options={initialValues['location']}
							disabled={inputsDisabled}
							onValue={value => handleInputChange('location', value)}
						/>
					</Styled.InputContainer>
					<Styled.ButtonContainer>
						<Button themeBtn="secondary" onClick={() => moveToBackPage()}>
							<BsIcons.BsArrowLeft size={24} />
						</Button>
						<Button
							themeBtn="primary"
							disabled={areThereErrors}
							onClick={() => {
								handleFoward();
							}}
						>
							Continuar
							<BsIcons.BsArrowRight size={24} />
						</Button>
					</Styled.ButtonContainer>
				</div>
			</Styled.Contents>
			<Modal
				closeModal={() => setOpen(false)}
				open={open}
				title="Para dar continuidade na sua simulação, será necessário informar a placa do seu veículo."
				contentText='Se não souber a placa, clique em "Não lembro a placa"'
			/>
		</Styled.Wrapper>
	);
};

export default VehicleData;
