import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
	${({ theme }) => css`
		width: 100%;
		margin-top: 1.6rem;
		padding: 1.6rem 2rem;
		border: 3px solid ${theme.colors.gray};
		border-radius: 0.5rem;
	`}
`;

export const Header = styled.div`
	${({ theme }) => css`
		width: 100%;

		display: flex;
		align-items: center;
		justify-content: space-between;

		color: ${theme.colors.blue.weight};

		> p {
			font-family: ${theme.font.family.medium}, sans-serif;
			font-weight: 500;
			font-style: italic;
			font-size: 1.3rem;
		}
	`}
`;

export const Contents = styled.div`
	${({ theme }) => css`
		width: 100%;
		margin-top: 1rem;

		display: grid;
		grid-template-columns: 1fr;
		gap: 2rem;

		color: ${theme.colors.blue.weight};

		p {
			font-family: ${theme.font.family.medium}, sans-serif;
			font-weight: 500;
			font-size: 1.1rem;
		}
	`}
`;
