import { ReactNode } from 'react';

import * as Styled from './styles';

type TextCardProps = {
	img?: string;
	title?: ReactNode;
	children: ReactNode;
};

const TextCard = ({ img, title, children }: TextCardProps) => {
	return (
		<Styled.Card>
			{title && (
				<Styled.Title>
					<img src={img} />
					{title}
				</Styled.Title>
			)}
			{children}
		</Styled.Card>
	);
};

export default TextCard;
