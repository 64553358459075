import styled, { css } from 'styled-components';

export const Container = styled.div`
	${({ theme }) => css`
		position: relative;

		margin-top: ${theme.spacings.xxsmall};

		display: flex;
		flex-direction: column;
	`}
`;

export const Label = styled.label<{ disabled: boolean }>`
	${({ theme, disabled }) => css`
		color: ${disabled ? theme.colors.blue.disabled : theme.colors.blue.weight};
		font-size: ${theme.font.sizes.xsmall};
		font-family: ${theme.font.family.regular}, sans-serif;
	`}
`;

export const Select = styled.div<{ alignment: boolean }>`
	${({ theme, alignment }) => css`
		height: 3rem;
		padding: 0.5rem 0.75rem;
		position: relative;

		display: inline-flex;
		align-items: center;
		justify-content: ${!alignment ? 'flex-end' : 'space-between'};

		font-size: ${theme.font.sizes.xsmall};
		font-family: ${theme.font.family.medium}, sans-serif;

		background-color: ${theme.colors.gray};
		color: ${theme.colors.blue.weight};

		border: 0;
		border-radius: 3px;

		cursor: pointer;

		svg {
			position: absolute;

			right: 1rem;
			top: 1rem;
		}

		&:active,
		&:hover {
			background-color: ${theme.colors.whiteGray};
		}
	`}
`;

export const HelperText = styled.p<{ error?: boolean }>`
	${({ theme, error }) => css`
		margin-top: 0.5rem;

		color: ${error ? theme.colors.error : theme.colors.blue.weight};

		font-size: 1.15rem;
		font-family: ${theme.font.family.regular}, sans-serif;
		font-style: italic;
	`}
`;

export const DropdownContainer = styled.div`
	${({ theme }) => css`
		width: 100%;
		max-height: 17rem;

		background-color: ${theme.colors.gray};
		z-index: ${theme.layers.alwaysOnTop};

		border-radius: 0 0 3px 3px;
		overflow: auto;
		position: absolute;
		top: 4.5rem;
	`}
`;

export const Option = styled.div`
	${({ theme }) => css`
		position: relative;

		width: 100%;
		margin: 0.5rem 0;
		padding: 0.5rem 0.75rem;

		background-color: ${theme.colors.gray};
		color ${theme.colors.blue.weight};

		font-family: ${theme.font.family.regular}, sans-serif;
		font-size: ${theme.font.sizes.xsmall};
		cursor: pointer;

		transition: 0.2s ease-out;

		float: right;

		&:active,
		&:hover {
			background-color: ${theme.colors.whiteGray};
		}
	`}
`;
