import styled, { css } from 'styled-components';

// import imgBg from 'assets/bg_image.svg';
import imgBg from 'assets/logos/bg_img.svg';

export const Wrapper = styled.section`
	${({ theme }) => css`
		background: url(${imgBg});
		background-size: 70%;
		background-position-x: 180%;
		background-position-y: 15%;
		background-repeat: no-repeat;
		display: flex;
		flex-direction: column;
		background-color: #5eb8d2;
		padding: 0 0 2rem;

		${theme.media.queryDesktop('768')} {
			background-size: 55%;
			background-position-x: 110%;
			background-position-y: 20%;
		}

		${theme.media.queryDesktop('1000')} {
			background-size: 50rem;
			background-position-y: bottom;
			background-position-x: 36%;
			height: 100vh;

			${theme.media.queryDesktopHeight('768')} {
				background-size: 25%;
			}
		}

		${theme.media.queryDesktop('1200')} {
			background-position-x: 36%;
		}

		${theme.media.queryDesktop('1400')} {
			background-position-x: 42%;
		}

		${theme.media.queryDesktop('1800')} {
			background-position-x: 45%;
		}
	`}
`;

export const InformativeText = styled.div`
	${({ theme }) => css`
		.white_text {
			color: ${theme.colors.white};
			font-size: 2.4rem;
			line-height: 0rem;
		}

		.bold_text {
			font-family: ${theme.font.family.superbold};
			font-size: 5.8rem;
			color: ${theme.colors.blue.weight};
			line-height: 6.1rem;
		}

		.bottom_text {
			color: ${theme.colors.blue.weight};
			font-size: 1.5rem;
		}
	`}
`;

export const SubText = styled.div`
	${({ theme }) => css`
		margin-top: 2.5rem;
		margin-bottom: 5rem;

		.subtext {
			color: ${theme.colors.white};
			font-family: ${theme.font.family.light};
			font-weight: 300;
			font-size: 2rem;
			line-height: 2.6rem;

			strong {
				font-family: ${theme.font.family.medium};
				font-weight: bold;
			}
		}
	`}
`;
export const Container = styled.div`
	${({ theme }) => css`
		padding: 0 ${theme.spacings.small};
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;

		${theme.media.queryDesktop('1000')} {
			max-width: 100%;
			width: 115rem;
			margin: 0 auto;

			flex-direction: row;
			justify-content: space-between;
			align-items: center;
		}
	`}
`;

export const SimulationArea = styled.div`
	${({ theme }) => css`
		background: ${theme.colors.white};
		border-radius: 1rem;
		text-align: center;
		padding: 5rem 4rem 2rem;

		.info_text {
			color: ${theme.colors.blue.weight};
			display: block;
			font-size: 2.2rem;
			margin-bottom: 1.4rem;
		}

		${theme.media.query('340')} {
			padding: 5rem 2rem 2rem;
		}

		${theme.media.queryDesktop('1000')} {
			border-radius: 2rem;
			width: 35%;
		}
	`}
`;

export const InputWrapper = styled.div``;

export const Footer = styled.div`
	${({ theme }) => css`
		margin-top: 4rem;

		Button {
			&:nth-child(1) {
				font-size: 2rem;
				font-family: ${theme.font.family.medium};
				font-weight: 500;
			}
		}
	`}
`;

export const KnowMore = styled.div`
	${({ theme }) => css`
		background: none;
		border: 0;
		font-size: ${theme.font.sizes.xsmall};
		height: 1.4rem;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 3.5rem auto 0;
		font-family: ${theme.font.family.medium};

		a {
			text-decoration: none;
			color: ${theme.colors.blue.weight};
		}
	`}
`;
