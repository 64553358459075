import cashSvg from '../../../../assets/icons/cash.svg';
import graphicSvg from '../../../../assets/icons/graphic.svg';
import seguritySvg from '../../../../assets/icons/segurity.svg';

export default [
	{
		text: (
			<span>
				Dinheiro na mão <strong>em até 24h</strong>
			</span>
		),
		img: cashSvg
	},
	{
		text: (
			<span>
				Menores taxas <strong>4x menos</strong>
			</span>
		),
		img: graphicSvg
	},
	{
		text: (
			<span>
				Segurança e confiança <strong>mais de 70 anos</strong> no mercado
			</span>
		),
		img: seguritySvg
	}
];
