import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
	${({ theme }) => css`
		margin-top: ${theme.spacings.xsmall};
		padding: 4rem 4.7rem;
		border-radius: 3px;
		border: solid 3px #f5f6fa;
	`}
`;
export const Loading = styled.div`
	${({ theme }) => css`
		display: flex;
		background-color: ${theme.colors.blue.pacificBlue};
		height: 1rem;
		width: 100%;
		border-radius: 999px;
		overflow: hidden;
		position: relative;
	`}
`;

export const Marker = styled(motion.div)`
	${({ theme }) => css`
		position: absolute;
		left: 0;
		display: block;
		background-color: ${theme.colors.blue.superLight};
		height: 1rem;
		width: 0.7rem;
		transform: skewX(-45deg);
	`}
`;

export const Message = styled.p`
	${({ theme }) => css`
		font-size: ${theme.font.sizes.xsmall};
		color: ${theme.colors.blue.weight};
		margin-bottom: ${theme.spacings.xxsmall};
		margin-top: ${theme.spacings.xxsmall};
		text-align: center;
	`}
`;
