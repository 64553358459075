import * as Yup from 'yup';

type Values = {
	[k: string]: string | boolean | number;
};

const fields = {
	howMuchNeedYou: Yup.string().required('Valor do emprestimo é obrigatório!'),
	reasonForLoan: Yup.string().required('Motivo é obrigatória!'),
	fullname: Yup.string().required('Nome é obrigatório!'),
	name: Yup.string().required('Nome é obrigatório!'),
	email: Yup.string()
		.email('Digite um e-mail valido')
		.required('E-mail é obrigatório!'),
	phone: Yup.string()
		.min(10, 'O numero de telefone deve ter no minimo 10 caracteres')
		.required('Telefone é obrigatório!'),
	accepted: Yup.boolean().optional(),
	occupation: Yup.string().required('Tipo Ocupação obrigatória!'),
	licensePlate: Yup.string().optional(),
	brand: Yup.string().required('Marca do carro obrigatória!'),
	model: Yup.string().required('Modelo do carro obrigatório!'),
	modelYear: Yup.date()
		.max(
			new Date().getFullYear() + 1,
			`Ano deve ser menor ou igual que ${new Date().getFullYear() + 1}`
		)
		.min(
			new Date().getFullYear() - 15,
			`Ano deve ser maior ou igual que ${new Date().getFullYear() - 15}`
		)
		.required('Ano do modelo obrigatório!')
		.typeError('Ano deve ter apenas numeros!'),
	location: Yup.string().required('Local de emplacamento obrigatório!'),
	paidoff: Yup.boolean()
		.required('Opção obrigatória!')
		.test('is-true', '', value => value === true),
	isCar: Yup.boolean()
		.required('Opção obrigatória!')
		.test('is-true', '', value => value === true),
	amountParcel: Yup.string().when('paidoff', {
		is: false,
		then: Yup.string().required('O valor não pode ser vazio.')
	}),
	valueParcel: Yup.string().when('paidoff', {
		is: false,
		then: Yup.string().required('O valor não pode ser vazio.')
	}),

	cpf: Yup.number().typeError('Cpf deve ser composto somente por numeros!'),
	city: Yup.string().required('Cidade deve ser informada!')
};

type Errors = {
	[k: string]: string;
};
function getValidationErrors(err: Yup.ValidationError): Errors {
	const validationErros: Errors = {};

	if (err.inner) {
		err.inner.forEach(error => {
			validationErros[error.path!] = error.message;
		});
	}

	return validationErros;
}

export const helpYouValidation = async (values: Values) => {
	try {
		const {
			fullname,
			howMuchNeedYou,
			reasonForLoan,
			accepted,
			cpf,
			occupation,
			paidoff,
			isCar
		} = fields;

		const schema = Yup.object().shape({
			fullname,
			howMuchNeedYou,
			reasonForLoan,
			accepted,
			cpf,
			occupation,
			paidoff,
			isCar
		});

		await schema.validate(values, { abortEarly: false });
	} catch (err) {
		if (err instanceof Yup.ValidationError) {
			return getValidationErrors(err);
		}
	}
};

export const vehicleDataValidations = async (values: Values) => {
	try {
		const {
			brand,
			licensePlate,
			amountParcel,
			valueParcel,
			location,
			model,
			modelYear
		} = fields;

		const schema = Yup.object().shape({
			brand,
			licensePlate,
			amountParcel,
			valueParcel,
			location,
			model,
			modelYear
		});

		await schema.validate(values, { abortEarly: false });
	} catch (err) {
		if (err instanceof Yup.ValidationError) {
			return getValidationErrors(err);
		}
	}
};

export const creditAnalizeValidations = async (values: Values) => {
	try {
		const { name, email, phone, cpf, occupation: tipoOcupacao } = fields;

		const schema = Yup.object().shape({
			name,
			email,
			phone,
			cpf,
			tipoOcupacao
		});

		await schema.validate(values, { abortEarly: false });
	} catch (err) {
		if (err instanceof Yup.ValidationError) {
			return getValidationErrors(err);
		}
	}
};

export const consortiumModalValidations = async (values: Values) => {
	try {
		const { name, email, phone, city } = fields;

		const schema = Yup.object().shape({
			name,
			email,
			phone,
			city
		});

		await schema.validate(values, { abortEarly: false });
	} catch (err) {
		if (err instanceof Yup.ValidationError) {
			return getValidationErrors(err);
		}
	}
};
