export function Capitalize(str: string) {
	return str
		.toLowerCase()
		.split(' ')
		.map(s => s.charAt(0).toUpperCase() + s.substring(1))
		.join(' ');
}

export function cpfFix(value: number) {
	let fixed = String(value);

	while (fixed.length < 11) fixed = '0' + fixed;

	return fixed;
}

export function cepFix(value: number) {
	let fixed = String(value);

	while (fixed.length < 8) fixed = '0' + fixed;

	return fixed;
}

export function roundedValue(str: string, casas: number) {
	if (str === null || !str || str === '') return 0;
	if (str.indexOf(',') != -1) str = str.replace(',', '.');
	if (!casas) casas = 0;
	casas = Math.pow(10, casas);
	const strs = parseFloat(str) * casas;
	return Math.floor(strs) / casas;
}

export function dateFix(value: number) {
	let fixed = String(value);

	while (fixed.length < 6) fixed = '0' + fixed;

	return fixed;
}

export function nthIndex(str: string, pat: string, n: number) {
	const L = str.length;
	let count = -1;
	while (n-- && count++ < L) {
		count = str.indexOf(pat, count);
		if (count < 0) break;
	}
	return count;
}

export function checkNewPos(str: string, pos: number, dir: number) {
	let aux = 0;
	aux = pos;

	if (aux === str.length) aux = -1;
	if (str[aux] && dir > 0)
		if ((str[aux].match(/[^0-9]/g) || []).length) aux += dir * 1;

	return aux;
}

export function checkNewPosReverse(str: string, pos: number, dir: number) {
	let aux = 0;
	aux = pos;

	if (aux === str.length) aux = -1;
	else {
		const resto = str.length - pos;

		if (str[aux] && dir > 0)
			if ((str[aux].match(/[^0-9]/g) || []).length) aux += dir * 1;
		aux = resto;
	}
	return aux;
}

export function mask(value: string, pattern: string, onlyNum?: boolean) {
	if (value === undefined) return '';
	let i = 0;
	let valueLen = value.length;
	const v = value.toString();

	if (onlyNum === true && (value.match(/[^0-9]/g) || []).length) valueLen--;

	let numero = pattern.length - (pattern.match(/#/g) || []).length;
	let newPattern = pattern;
	while (numero > 0) {
		newPattern += '#';
		numero--;
	}
	newPattern = newPattern
		.replace(/#/g, () => {
			if (i <= valueLen && newPattern[i] !== '#') {
				valueLen++;
			}
			return v[i++] || '';
		})
		.slice(0, Math.min(valueLen, pattern.length));

	if (newPattern[newPattern.length - 1] === pattern[newPattern.length - 1])
		return newPattern.slice(0, newPattern.length - 1);
	else return newPattern;
}

export function unMask(value: string, pattern: string) {
	if (value === undefined) return '';
	// const v = value.toString();

	const j = new RegExp(pattern);
	const newValue = value.split(j);
	const newString = newValue.join('');

	return newString;
}

export function telMask(value: string | boolean) {
	if (value === undefined) return '';
	let i = 0;
	const fixPattern = '(##) ####-####';
	const celPattern = '(##) #####-####';
	let pattern = fixPattern;
	const v = value.toString();

	const finalValue = unMask(v, '[^0-9]');
	let valueLen = finalValue.length;

	if (valueLen > 10) pattern = celPattern;

	let numero = pattern.length - (pattern.match(/#/g) || []).length;
	let newPattern = pattern;
	while (numero > 0) {
		newPattern += '#';
		numero--;
	}
	newPattern = newPattern
		.replace(/#/g, () => {
			if (i <= valueLen && newPattern[i] !== '#') {
				valueLen++;
			}
			return finalValue[i++] || '';
		})
		.slice(0, Math.min(valueLen, pattern.length));

	while (newPattern[newPattern.length - 1] === pattern[newPattern.length - 1]) {
		newPattern = newPattern.slice(0, newPattern.length - 1);
		if (newPattern.length === 0) break;
	}

	return newPattern;
}

export function cepMask(value: string) {
	if (value === undefined) return '';
	let i = 0;

	const v = value.toString();
	const pattern = '#####-###';

	const finalValue = unMask(v, '[^0-9]');
	let valueLen = finalValue.length;

	let numero = pattern.length - (pattern.match(/#/g) || []).length;
	let newPattern = pattern;
	while (numero > 0) {
		newPattern += '#';
		numero--;
	}
	newPattern = newPattern
		.replace(/#/g, () => {
			if (i <= valueLen && newPattern[i] !== '#') {
				valueLen++;
			}
			return finalValue[i++] || '';
		})
		.slice(0, Math.min(valueLen, pattern.length));

	if (newPattern[newPattern.length - 1] === pattern[newPattern.length - 1])
		return newPattern.slice(0, newPattern.length - 1);
	else return newPattern;
}

export function cpfMask(value: string) {
	if (value === undefined) return '';
	let i = 0;

	const v = value.toString();
	const pattern = '###.###.###-##';

	const finalValue = unMask(v, '[^0-9]');
	let valueLen = finalValue.length;

	let numero = pattern.length - (pattern.match(/#/g) || []).length;
	let newPattern = pattern;
	while (numero > 0) {
		newPattern += '#';
		numero--;
	}
	newPattern = newPattern
		.replace(/#/g, () => {
			if (i <= valueLen && newPattern[i] !== '#') {
				valueLen++;
			}
			return finalValue[i++] || '';
		})
		.slice(0, Math.min(valueLen, pattern.length));

	if (newPattern[newPattern.length - 1] === pattern[newPattern.length - 1])
		return newPattern.slice(0, newPattern.length - 1);
	else return newPattern;
}

export function dateMAMask(value: string) {
	if (value === undefined) return '';
	let i = 0;

	const v = value.toString();
	const pattern = '##/####';

	const finalValue = unMask(v, '[^0-9]');
	let valueLen = finalValue.length;

	let numero = pattern.length - (pattern.match(/#/g) || []).length;
	let newPattern = pattern;
	while (numero > 0) {
		newPattern += '#';
		numero--;
	}
	newPattern = newPattern
		.replace(/#/g, () => {
			if (i <= valueLen && newPattern[i] !== '#') {
				valueLen++;
			}
			return finalValue[i++] || '';
		})
		.slice(0, Math.min(valueLen, pattern.length));

	if (newPattern[newPattern.length - 1] === pattern[newPattern.length - 1])
		return newPattern.slice(0, newPattern.length - 1);
	else return newPattern;
}

export function dateDMAMask(value: string) {
	if (value === undefined) return '';
	let i = 0;

	const v = value.toString();
	const pattern = '##/##/####';

	const finalValue = unMask(v, '[^0-9]');
	let valueLen = finalValue.length;

	let numero = pattern.length - (pattern.match(/#/g) || []).length;
	let newPattern = pattern;
	while (numero > 0) {
		newPattern += '#';
		numero--;
	}
	newPattern = newPattern
		.replace(/#/g, () => {
			if (i <= valueLen && newPattern[i] !== '#') {
				valueLen++;
			}
			return finalValue[i++] || '';
		})
		.slice(0, Math.min(valueLen, pattern.length));

	if (newPattern[newPattern.length - 1] === pattern[newPattern.length - 1])
		return newPattern.slice(0, newPattern.length - 1);
	else return newPattern;
}

export function currencyFormat(value: number, decimal = false) {
	// let num = Number(value) || 0;
	// if (num === null) {
	//   return '0,00';
	// }
	// if (!decimal) {
	//   num = Math.floor(num);
	// }

	// let str = num
	//   .toLocaleString('pt-BR')
	//   .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

	// if (decimal) {
	//   if (str[str.length - 2] === ',') str = str + '0';
	//   if (str[str.length - 3] !== ',') str = str + ',00';
	// }

	// return str;

	if (value === undefined) return '';

	let pattern = decimal ? '###.###.###.###.###,##' : '###.###.###.###.###';
	const reversePattern = decimal
		? '##,###.###.###.###.###'
		: '###.###.###.###.###';
	let i = 0;
	const v = value.toString();

	const numNumbers = (v.match(/[0-9]/g) || []).length;

	const patternCutIndex = nthIndex(reversePattern, '#', numNumbers);
	const maxSize = decimal ? 4 : 1;

	let cutPattern = pattern.slice(
		pattern.length - Math.max(patternCutIndex + 1, maxSize),
		pattern.length
	);

	pattern = cutPattern;

	let finalValue = unMask(v, '[^0-9]');
	while (finalValue.length < maxSize) finalValue = '0' + finalValue;

	let valueLen = finalValue.length;
	if ((v.match(/[^0-9]/g) || []).length) valueLen--;

	let numero = cutPattern.length - (cutPattern.match(/#/g) || []).length;
	while (numero > 0) {
		cutPattern += '#';
		numero--;
	}
	cutPattern = cutPattern.replace(/#/g, () => {
		if (i <= valueLen && cutPattern[i] !== '#') {
			valueLen++;
		}

		return finalValue[i++] || '';
	});

	while (cutPattern[0] === '0') {
		if (cutPattern.length <= 4) break;
		cutPattern = cutPattern.slice(1, cutPattern.length);
	}

	return cutPattern;
}

export function currencyFormatWithCents(value: string, withRS = true) {
	if (value === undefined) return '';

	let pattern = '###.###.###.###.###,##';
	const reversePattern = '##,###.###.###.###.###';
	let i = 0;
	const v = value.toString();

	const numNumbers = (value.match(/[0-9]/g) || []).length;

	const patternCutIndex = nthIndex(reversePattern, '#', numNumbers);

	let cutPattern = pattern.slice(
		pattern.length - Math.max(patternCutIndex + 1, 4),
		pattern.length
	);

	pattern = cutPattern;

	let finalValue = unMask(v, '[^0-9]');
	while (finalValue.length < 3) finalValue = '0' + finalValue;

	let valueLen = finalValue.length;
	if ((value.match(/[^0-9]/g) || []).length) valueLen--;

	let numero = cutPattern.length - (cutPattern.match(/#/g) || []).length;
	while (numero > 0) {
		cutPattern += '#';
		numero--;
	}
	cutPattern = cutPattern.replace(/#/g, () => {
		if (i <= valueLen && cutPattern[i] !== '#') {
			valueLen++;
		}

		return finalValue[i++] || '';
	});

	while (cutPattern[0] === '0') {
		if (cutPattern.length <= 4) break;
		cutPattern = cutPattern.slice(1, cutPattern.length);
	}

	if (withRS) cutPattern = 'R$ ' + cutPattern;

	return cutPattern;
}

export function currencyFormatBrl(value: number) {
	return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
}

export function phoneMask(value: string) {
	const array = value.split('');

	if (array[0] === '0') {
		return value
			.replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
			.replace(/(\d{0})(\d)/, '$1($2') // inicia o texto com (
			.replace(/(\d{3})(\d)/, '$1)$2') // fecha o ) depois de 2 digitos
			.replace(/(\d{5})(\d{1,2})/, '$1-$2') // após 5 digitos ele poe o -
			.replace(/(-\d{4})\d+?$/, '$1'); // não deixa escrever mais nada após 4 dígitos depois do -
	}

	return value
		.replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
		.replace(/(\d{0})(\d)/, '$1($2') // inicia o texto com (
		.replace(/(\d{2})(\d)/, '$1)$2') // fecha o ) depois de 2 digitos
		.replace(/(\d{5})(\d{1,2})/, '$1-$2') // após 5 digitos ele poe o -
		.replace(/(-\d{4})\d+?$/, '$1'); // não deixa escrever mais nada após 4 dígitos depois do -
}

export default function desmaskTelefone(value: string) {
	const desmaskValue = value.replace(/[^\d]+/g, '');
	const array = desmaskValue.split('');

	if (array[0] === '0') {
		const ddd = desmaskValue.slice(0, 3);
		const phone = desmaskValue.slice(3, 13);

		return { ddd, phone };
	}

	const ddd = desmaskValue.slice(0, 2);
	const phone = desmaskValue.slice(2, 12);

	return { ddd, phone };
}

export function maskPhoneOrCel(v: string) {
	const arrayString = v.split('');

	if (arrayString[0] === '0') {
		v = v.replace(/\D/g, ''); //Remove tudo o que não é dígito
		v = v.replace(/^(\d{3})(\d)/g, '($1) $2'); //Coloca parênteses em volta dos dois primeiros dígitos
		v = v.replace(/(\d)(\d{4})$/, '$1-$2');

		return v;
	}

	v = v.replace(/\D/g, ''); //Remove tudo o que não é dígito
	v = v.replace(/^(\d{2})(\d)/g, '($1) $2'); //Coloca parênteses em volta dos dois primeiros dígitos
	v = v.replace(/(\d)(\d{4})$/, '$1-$2'); //Coloca hífen entre o quarto e o quinto dígitos
	return v;
}

export function unMaskPhoneValue(value: string) {
	return value.replace(/[^\d]+/g, '');
}
