import { forwardRef } from 'react';
import SlickSlider, { Settings } from 'react-slick';
import * as Styled from './styles';

export type SettingsSlider = Settings;

type SliderProps = {
	children: React.ReactNode;
	settings: SettingsSlider;
};

const Slider: React.ForwardRefRenderFunction<SlickSlider, SliderProps> = (
	{ children, settings },
	ref
) => (
	<Styled.Wrapper>
		<SlickSlider ref={ref} {...settings}>
			{children}
		</SlickSlider>
	</Styled.Wrapper>
);

export default forwardRef(Slider);
