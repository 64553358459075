import styled, { css } from 'styled-components';
import * as InputStyled from 'components/InputField/styles';
import * as AutoCompleteStyled from 'components/AutoComplete/styles';
import * as ButtonsStyled from 'components/Buttons/styles';

export const OptionalAccepted = styled.div`
	${({ theme }) => css`
		width: 100%;
		display: flex;
		justify-content: space-between;
		margin-top: 1rem;
	`}
`;

export const OptionalAcceptedWrapper = styled.div`
	${({ theme }) => css`
		margin-top: ${theme.spacings.xsmall};
		width: 100%;
		font-size: ${theme.font.sizes.xsmall};
		margin-bottom: 2rem;
	`}
`;

export const Wrapper = styled.section``;
export const Content = styled.div`
	${({ theme }) => css`
		width: 100%;
		display: flex;
		justify-content: center;
		flex-direction: column;
		padding: 2.3rem 4rem 12rem;

		form {
			width: 100%;
			margin-top: ${theme.spacings.xsmall};

			${InputStyled.Container}:not(:first-child), ${AutoCompleteStyled.Container} {
				margin-top: 1.8rem;
			}
		}

		${theme.media.queryDesktop('1000')} {
			width: 50rem;
			margin: 0 auto;
		}
	`}
`;

export const ButtonWrapper = styled.div`
	display: flex;
	flex-direction: row-reverse;

	margin-top: 2.2rem;

	${ButtonsStyled.Button}:first-child {
		font-size: 1.5rem;
		font-weight: 500;
		> svg {
			margin-left: 1.8rem;
		}
	}

	${ButtonsStyled.Button}:last-child {
		width: 7rem;
		margin-right: 1.2rem;
	}
`;

export const About = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 5rem;

	p {
		font-size: 1rem;
		margin-left: 0.02rem;
	}
`;

export const WrapperCepError = styled.div`
	${({ theme }) => css`
		margin-top: ${theme.spacings.xsmall};
		span {
			margin-top: 0.5rem;

			color: ${theme.colors.error};

			font-size: 1.15rem;
			font-family: ${theme.font.family.regular}, sans-serif;
			font-style: italic;
		}
	`}
`;

export const RadioContainer = styled.div<{ disabled?: boolean }>`
	${({ theme, disabled }) => css`
		margin-top: ${theme.spacings.xsmall};

		display: grid;
		grid-template-columns: 1fr 1fr;

		> p {
			margin-bottom: 1.2rem;

			grid-column: 1/3;

			font-family: ${theme.font.family.regular}, sans-serif;
			font-size: 1.3rem;
			font-weight: 400;

			color: ${disabled
				? theme.colors.blue.disabled
				: theme.colors.blue.weight};
		}
	`}
`;

export const Error = styled.span`
	${({ theme }) => css`
		margin-top: 0.5rem;

		color: ${theme.colors.error};

		font-size: 1.15rem;
		font-family: ${theme.font.family.regular}, sans-serif;
		font-style: italic;
	`}
`;

export const HideInputGrid = styled.div`
	${({ theme }) => css`
		margin-top: ${theme.spacings.xsmall};

		display: grid;
		grid-template-columns: 1fr;
		gap: 1.2rem ${theme.spacings.xxsmall};
	`}
`;

export const InformativeText = styled.div`
	${({ theme }) => css`
		.white_text {
			color: ${theme.colors.white};
			font-size: 2.4rem;
			line-height: 0rem;
		}

		.bold_text {
			font-family: ${theme.font.family.superbold};
			font-size: 5.8rem;
			color: ${theme.colors.blue.weight};
			line-height: 6.1rem;
		}

		.bottom_text {
			color: ${theme.colors.blue.weight};
			font-size: 1.5rem;
		}
	`}
`;
