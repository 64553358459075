/* eslint-disable react/display-name */
import React, {
	forwardRef,
	InputHTMLAttributes,
	useRef,
	useState
} from 'react';
import useClickOutside from 'hooks/useClickOutside';
import * as Styled from './styles';
import * as BsIcons from 'react-icons/bs';
import SpinnerLoading from 'components/SpinnerLoading';

export type InputProps = InputHTMLAttributes<HTMLInputElement>;

type OptionsProps = {
	id: string | number;
	name: string;
};

type AutoCompleteProps = {
	id: string;
	inputValue?: string | number | boolean;
	label?: string;
	options: Array<OptionsProps>;
	onValue: (value: string) => void;
	disabled?: boolean;
	error?: string;
	isLoading?: boolean;
} & InputProps;

const AutoComplete = forwardRef<HTMLInputElement, AutoCompleteProps>(
	(
		{
			id,
			label,
			options,
			onValue,
			disabled,
			error,
			inputValue = '',
			isLoading,
			...props
		},
		ref
	) => {
		const [isExpanded, setExpanded] = useState<boolean>(false);
		const wrapperRef = useRef<HTMLDivElement>(null);

		const expandContainer = () => {
			setExpanded(true);
		};

		const collapseContainer = () => {
			setExpanded(false);
		};

		useClickOutside(wrapperRef, () => {
			collapseContainer();
		});

		const filterOptions = options.filter((data: OptionsProps) =>
			data.name.toLowerCase().includes(String(inputValue).toLowerCase())
		);

		const handleChangeSelect = (value: string) => {
			onValue(value);
			setExpanded(false);
		};

		return (
			<Styled.Container ref={wrapperRef}>
				<Styled.Label disabled={disabled} htmlFor={id}>
					{label}
				</Styled.Label>
				<Styled.InputWrapper>
					<Styled.Input
						disabled={disabled}
						autoComplete="off"
						id={id}
						ref={ref}
						value={String(inputValue)}
						onChange={event => {
							onValue(event.target.value);
						}}
						onFocus={expandContainer}
						{...props}
					/>
					<Styled.WrapperIcon>
						<BsIcons.BsChevronDown size={12} />
					</Styled.WrapperIcon>
				</Styled.InputWrapper>

				{isExpanded && (
					<Styled.SuggestContainer>
						{filterOptions.length !== 0 ? (
							filterOptions.map((element: OptionsProps, index: number) => (
								<Styled.OptionContainer
									onClick={() => {
										handleChangeSelect(element.name);
									}}
									key={index}
								>
									<span>{element.name}</span>
								</Styled.OptionContainer>
							))
						) : isLoading ? (
							<SpinnerLoading />
						) : (
							<Styled.NoResults>
								<span>Sem resultados</span>
							</Styled.NoResults>
						)}
					</Styled.SuggestContainer>
				)}
				{!!error && <Styled.Error>{error}</Styled.Error>}
			</Styled.Container>
		);
	}
);

export default AutoComplete;
