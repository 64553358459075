import api from './api';
import { GetAuthProps } from './models';

const RESOURCE = '';

const getAuth = async (props: GetAuthProps) => {
	const encodedParameters = new URLSearchParams(props);
	return await api.post(RESOURCE, encodedParameters);
};

const keyclockServices = {
	getAuth
};

export default keyclockServices;
