import { MoneyContext } from './../providers/MoneyProvider';
import { useContext } from 'react';

const useMoney = () => {
	const context = useContext(MoneyContext);

	if (!context) throw new Error('useMoney must be used with MoneyContext');

	return context;
};

export default useMoney;
