import styled, { css } from 'styled-components';

export const Card = styled.div`
	${({ theme }) => css`
		width: 100%;
		margin-top: 2rem;
		padding: 2rem;

		background-color: ${theme.colors.blue.outlineBlue};

		border-radius: 1.5rem;
	`}
`;

export const OptionsCard = styled.div`
	${({ theme }) => css`
		padding: 2.5rem 1.6rem 2.5rem 2rem;
		display: flex;

		background-color: ${theme.colors.white};

		border-radius: 1rem;

		> span {
			margin-left: 1.5rem;
			display: inline-flex;
			flex-direction: column;
			justify-content: flex-end;

			font-family: ${theme.font.family.light};
			font-size: ${theme.font.sizes.small};
			line-height: 2.2rem;

			color: ${theme.colors.blue.weight};
		}

		strong {
			font-family: ${theme.font.family.bold};
			font-size: 2.1rem;
		}

		& + div {
			margin-top: 2rem;
		}

		&:nth-child(2) {
			padding-left: 2.5rem;

			> span {
				margin-left: 2rem;
			}
		}
	`}
`;
