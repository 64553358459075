import * as Styled from './styles';
import Button from 'components/Buttons';

type ParcelNotFoundProps = {
	onUpdate?: () => void;
	errorVariant?: boolean;
};

const ParcelNotFound = ({ onUpdate, errorVariant }: ParcelNotFoundProps) => {
	return (
		<Styled.Wrapper>
			<Styled.Header>
				<p>Ops! Algo deu errado.</p>
			</Styled.Header>
			<Styled.Contents>
				{!errorVariant && (
					<Button onClick={onUpdate}>Atualizar parcelas</Button>
				)}
			</Styled.Contents>
		</Styled.Wrapper>
	);
};

export default ParcelNotFound;
