import { InputHTMLAttributes } from 'react';
import * as Styled from './styles';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
	id: string;
	label?: string;
	helperText?: string;
	error?: string;
	disabled?: boolean;
}

const InputField = ({
	id,
	label,
	helperText,
	error,
	disabled = false,
	...props
}: InputProps) => {
	return (
		<Styled.Container>
			{helperText !== undefined && !error && (
				<Styled.HelperText disabled={disabled}>{helperText}</Styled.HelperText>
			)}
			{!!error && (
				<Styled.HelperText error={!!error}>{error}</Styled.HelperText>
			)}
			<Styled.Input id={id} {...props} disabled={disabled} />
			{label !== undefined && (
				<Styled.Label disabled={disabled} htmlFor={id}>
					{label}
				</Styled.Label>
			)}
		</Styled.Container>
	);
};

export default InputField;
