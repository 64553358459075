/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useRef } from 'react';
import * as Styled from './styles';
import SlickSlider from 'react-slick';

import Slider, { SettingsSlider } from 'components/Slider';
import CardSlider from 'components/CardSlider';

import { InstallmentsOptions } from 'context/models/money';

type OptionsInstallmentsSliderProps = {
	options: InstallmentsOptions[];
	handleFocus?: (index: number) => void;
};

const OptionsInstallmentsSlider = ({
	options,
	handleFocus
}: OptionsInstallmentsSliderProps) => {
	const sliderRef = useRef<SlickSlider>(null);

	const [currentSlide, setCurrentySlide] = useState(1);

	const handleLeaveFocusInputMoney = () => {
		const inputMoney = document.getElementById('inputMoneyVariant');
		inputMoney?.blur();
	};

	useEffect(() => {
		handleFocus && handleFocus(currentSlide);
	}, [currentSlide, handleFocus]);

	const settings: SettingsSlider = {
		initialSlide: 1,
		infinite: false,
		arrows: false,
		slidesToShow: 3,
		beforeChange: (_, current: number) => {
			setCurrentySlide(current);
		},
		onSwipe: () => {
			handleLeaveFocusInputMoney();
		},
		responsive: [
			{
				breakpoint: 360,
				settings: {
					className: 'center',
					centerPadding: '80px',
					centerMode: true,
					initialSlide: 1,
					infinite: false,
					arrows: false,
					slidesToShow: 1
				}
			},
			{
				breakpoint: 400,
				settings: {
					className: 'center',
					centerPadding: '95px',
					centerMode: true,
					initialSlide: 1,
					infinite: false,
					arrows: false,
					slidesToShow: 1
				}
			},
			{
				breakpoint: 450,
				settings: {
					className: 'center',
					centerPadding: '110px',
					centerMode: true,
					initialSlide: 1,
					infinite: false,
					arrows: false,
					slidesToShow: 1
				}
			},
			{
				breakpoint: 1920,
				settings: {
					className: 'center',
					centerPadding: '80px',
					centerMode: true,
					initialSlide: 0,
					infinite: false,
					arrows: false,
					slidesToShow: 1
				}
			},
			{
				breakpoint: 2561,
				settings: {
					className: 'center',
					centerPadding: '80px',
					centerMode: true,
					initialSlide: 0,
					infinite: false,
					arrows: false,
					slidesToShow: 1
				}
			}
		]
	};

	return (
		<Styled.Wrapper>
			<Slider ref={sliderRef} settings={settings}>
				{options.map((item, index) => {
					return (
						<CardSlider
							active={index === currentSlide}
							key={`slide - ${index}`}
							installments={item.installments}
							value={item.value}
							interest={item.interest}
							item={item}
							onClick={() => {
								sliderRef.current!.slickGoTo(index);
							}}
						/>
					);
				})}
			</Slider>
		</Styled.Wrapper>
	);
};

export default OptionsInstallmentsSlider;
