import styled, { css } from 'styled-components';

type ImageBlockProps = {
	img: string;
};

export const Card = styled.div`
	${({ theme }) => css`
		width: 100%;
		margin-top: 2rem;
		padding: 2.8rem 2rem;

		background-color: ${theme.colors.blue.outlineBlue};

		border-radius: 1.5rem;
	`}
`;

export const Options = styled.div`
	${({ theme }) => css`
		width: 100%;
		display: inline-flex;
		background-color: ${theme.colors.white};
		border-radius: 1rem;

		> img {
			width: 100px;
		}

		& + div {
			margin-top: 1.6rem;
		}
	`}
`;

export const ImageBlock = styled.div<ImageBlockProps>`
	${({ img }) => css`
		width: 9.9rem;
		height: 7.4rem;

		border-radius: 1rem 0 0 1rem;

		background-image: url(${img});
		background-size: 100%;
		background-position-x: 25%;
	`}
`;

export const TextCard = styled.div`
	${({ theme }) => css`
		padding-left: 1.6rem;

		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;

		color: ${theme.colors.blue.weight};

		strong {
			font-family: ${theme.font.family.medium};
			font-size: 2.1rem;
		}

		p {
			font-family: ${theme.font.family.light};
			font-size: 1.4rem;
		}
	`}
`;
