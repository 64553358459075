import styled, { css, DefaultTheme } from 'styled-components';

export const Card = styled.div<{ active: boolean }>`
	${({ theme, active }) => css`
		box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.26);
		border-radius: 1rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		background: ${theme.colors.white};
		height: 10rem;
		width: 16rem;
		color: ${theme.colors.blue.weight};
		padding: ${theme.spacings.xsmall} 0;
		margin-top: 1rem;
		margin-bottom: 1rem;

		${active && cardModifiers.active(theme)}
	`}
`;
export const Installments = styled.span`
	${({ theme }) => css`
		font-size: ${theme.font.sizes.xsmall};
		margin-bottom: ${theme.spacings.xxsmall};
	`}
`;
export const ValueInstallments = styled.span`
	${({ theme }) => css`
		font-size: 2.8rem;
		font-family: ${theme.font.family.bold};
	`}
`;
export const Interest = styled.span`
	${({ theme }) => css`
		font-size: ${theme.font.sizes.xsmall};
		margin-top: ${theme.spacings.xxsmall};
	`}
`;

const cardModifiers = {
	active: (theme: DefaultTheme) => css`
		background: ${theme.colors.blue.pacificBlue};
		color: ${theme.colors.white};
		width: 17.6rem;
		height: 11.8rem;
		margin-top: 0;
		margin-left: -8px;
		transition: all 0.3s ease-in-out;

		${ValueInstallments} {
			font-size: 3.3rem;
		}
	`
};
