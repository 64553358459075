import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
	margin-top: 1.2rem;
`;

export const Button = styled.button`
	${({ theme }) => css`
		padding: ${theme.spacings.xxsmall} ${theme.spacings.xsmall};

		background-color: ${theme.colors.blue.pacificBlue};
		color: ${theme.colors.white};

		border: 0;
		border-radius: 3px;

		font-family: ${theme.font.family.medium}, sans-serif;
		font-size: ${theme.font.sizes.xsmall};
		font-weight: 500;
	`}
`;
