import { Route, RouteProps, Switch, useLocation } from 'react-router-dom';
import Home from 'pages/Home/Index';
import HelpYou from 'pages/HelpeYou';
import SwitchCondition from 'pages/SwitchCondition';
import VehicleData from 'pages/VehicleData';
import RequestedAnalyisis from 'pages/RequestedAnalysis';
import { useEffect } from 'react';

type Routes = RouteProps & {
	routename: string;
};

const routes: Routes[] = [
	{
		routename: 'HelpYou',
		path: '/help-you',
		component: HelpYou,
		exact: true
	},
	{
		routename: 'VehicleData',
		path: '/vehicle-data',
		component: VehicleData,
		exact: true
	},
	{
		routename: 'SwitchCondition',
		path: '/switch-condition',
		component: SwitchCondition,
		exact: true
	},
	{
		routename: 'RequestedAnalysis',
		path: '/requested-analysis',
		component: RequestedAnalyisis,
		exact: true
	},
	{
		routename: 'home',
		path: '/:utm_source?/:utm_medium?/:utm_campaing?',
		component: Home,
		exact: false
	}
];

const Routes = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);
	return (
		<Switch>
			{routes.map(({ routename, ...rest }) => (
				<Route key={routename} {...rest} />
			))}
		</Switch>
	);
};

export default Routes;
