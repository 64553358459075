import api from './api';

const RESOURCE = 'webhook/custom/lead/798d1c2813cbdf8bcdb388db0e32d496';

const postIncludeLead = async <T>(body: any): Promise<T> => {
	const customParameters = `?name=name&email=email&phone=phone`;

	const response = await api.post<T>(`${RESOURCE}${customParameters}`, body);

	return response.data;
};

const appSellFluxServices = {
	postIncludeLead: postIncludeLead
};

export default appSellFluxServices;
