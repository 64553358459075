import styled, { css } from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
`;

export const Input = styled.input`
	${({ theme }) => css`
		padding: 0.5rem 0.75rem;
		width: 100%;
		height: 3rem;

		font-size: ${theme.font.sizes.xsmall};
		font-family: ${theme.font.family.medium}, sans-serif;

		color: ${theme.colors.blue.weight};
		background-color: ${theme.colors.gray};

		border: 0;
		border-radius: 3px;
	`}
`;

export const Label = styled.label<{ disabled?: boolean }>`
	${({ theme, disabled }) => css`
		color: ${disabled ? 'rgba(1, 45, 92, 0.4)' : theme.colors.blue.weight};
		font-size: ${theme.font.sizes.xsmall};
		font-family: ${theme.font.family.regular}, sans-serif;
	`}
`;

export const SuggestContainer = styled.div`
	${({ theme }) => css`
		width: 100%;
		max-height: 17rem;

		background-color: ${theme.colors.gray};

		border-radius: 0 0 3px 3px;
		overflow: auto;
	`}
`;

export const OptionContainer = styled.div`
	${({ theme }) => css`
		position: relative;

		width: 100%;
		margin: 0.5rem 0;
		padding: 0.5rem 0.75rem;

		background-color: ${theme.colors.gray};
		color ${theme.colors.blue.weight};

		font-family: ${theme.font.family.regular}, sans-serif;
		font-size: ${theme.font.sizes.xsmall};
		cursor: pointer;

		transition: 0.2s ease-out;

		&:active,
		&:hover {
			background-color: ${theme.colors.whiteGray};
		}

		> span {
			color: ${theme.colors.blue.weight};
		}
	`}
`;

export const NoResults = styled.div`
	${({ theme }) => css`
		width: 100%;
		margin: 0.5rem 0;
		padding: 0.5rem 0.75rem;

		display: flex;
		justify-content: center;

		background-color: ${theme.colors.gray};

		font-family: ${theme.font.family.regular}, sans-serif;
		font-style: italic;
		font-size: ${theme.font.sizes.xsmall};

		> span {
			color: ${theme.colors.blue.weight};
		}
	`}
`;

export const WrapperIcon = styled.div`
	${({ theme }) => css`
		color: ${theme.colors.blue.weight};
		position: absolute;
		right: 1rem;
		top: 0.4rem;
	`}
`;

export const InputWrapper = styled.div`
	position: relative;
`;

export const Error = styled.p`
	${({ theme }) => css`
		margin-top: 0.5rem;

		color: ${theme.colors.error};

		font-size: 1.15rem;
		font-family: ${theme.font.family.regular}, sans-serif;
		font-style: italic;
	`}
`;
