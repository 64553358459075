import React from 'react';

import useSteps from 'context/hooks/useSteps';
import StepMarker from 'components/StepMarker';

import logo from 'assets/logos/logo.svg';

import * as Styled from './styles';

export type HeaderProps = {
	translucent?: boolean;
	headerSize?: 'small' | 'large';
	nonSteps?: boolean;
};

const Header = ({
	translucent = false,
	headerSize = 'large',
	nonSteps = false
}: HeaderProps) => {
	const { steps } = useSteps();

	return (
		<React.Fragment>
			<Styled.Header translucent={translucent} headerSize={headerSize}>
				<Styled.Section>
					<Styled.Image to={`/${location.search}`}>
						<img src={logo} alt="Logo sinosserra branca" />
					</Styled.Image>
				</Styled.Section>
			</Styled.Header>

			{headerSize === 'large' && !nonSteps && (
				<Styled.Steps>
					{steps.map(item => (
						<StepMarker
							active={window.location.pathname === item.activePath}
							completed={item.completed}
							key={item.activePath}
							step={item.step}
						/>
					))}
				</Styled.Steps>
			)}
		</React.Fragment>
	);
};

export default Header;
