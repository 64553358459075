import { ContextProps, ProviderProps, UserData } from 'context/models/user';
import { createContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

export const UserDataContext = createContext<ContextProps>({} as ContextProps);

const UserDataProvider = ({ children }: ProviderProps) => {
	const [userData, setUserData] = useState<UserData>({});
	const history = useHistory();

	const getUserData = (data: UserData) => {
		setUserData(data);
	};

	const updateUserData = (field: string, value: string | boolean) => {
		setUserData(oldUserData => ({ ...oldUserData, [field]: value }));
	};

	const clearUserData = () => setUserData({});

	return (
		<UserDataContext.Provider
			value={{ userData, getUserData, updateUserData, clearUserData }}
		>
			{children}
		</UserDataContext.Provider>
	);
};

export default UserDataProvider;
