import { useMotionValue, useTransform } from 'framer-motion';
import * as Styled from './styles';

type LoadingProps = {
	message: string;
};

const Loading = ({ message }: LoadingProps) => {
	return (
		<Styled.Wrapper>
			<Styled.Message>{message}</Styled.Message>
			<Styled.Loading>
				{Array.from(Array(100).keys()).map((item, index) => (
					<Styled.Marker
						key={item}
						initial={{
							left: '-50%',
							marginLeft: `${15 * index}px`
						}}
						animate={{ left: '-5%', marginLeft: `${15 * index}px` }}
						transition={{ duration: 2, yoyo: Infinity }}
					/>
				))}
			</Styled.Loading>
			<Styled.Message>aguarde um momento.</Styled.Message>
		</Styled.Wrapper>
	);
};

export default Loading;
