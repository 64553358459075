import { VehiculeContext } from './../providers/VehiculeProvider';
import { useContext } from 'react';

const useVehicule = () => {
	const context = useContext(VehiculeContext);

	if (!context)
		throw new Error('useVehicule must be used with VehiculeContext');

	return context;
};

export default useVehicule;
