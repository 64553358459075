import { formatCurrency } from 'functions';
import useClickOutside from 'hooks/useClickOutside';
import React, { forwardRef, useRef } from 'react';
import * as GoIcons from 'react-icons/go';
import * as RiIcons from 'react-icons/ri';

import * as Styled from './styles';

const currencyConfig = {
	locale: 'pt-BR',
	formats: {
		number: {
			BRL: {
				style: 'currency',
				currency: 'BRL',
				minimumFractionDigits: 0,
				maximumFractionDigits: 0
			}
		}
	}
};

type InputMoneyVariantProps = {
	id?: string;
	initialValue: string | number;
	max?: number;
	error: string;
	updateValueMinMax: (value: number) => void;
	onChange: (value: number) => void;
	onBlur?: (value: number) => void;
};

const InputMoneyVariant = forwardRef<HTMLInputElement, InputMoneyVariantProps>(
	(
		{ initialValue, updateValueMinMax, max = 0, onChange, error, onBlur, id },
		ref
	) => {
		const warpperRef = useRef<HTMLDivElement>(null);
		const maxValue = Number(max.toFixed());

		const handleInputChange = (
			event: React.ChangeEvent<HTMLInputElement>,
			value: number
		) => {
			!!onChange && onChange(value);
		};

		const handleValue = (action: 'plus' | 'subtract') => {
			switch (action) {
				case 'plus': {
					if (maxValue === 150000 && initialValue < maxValue) {
						updateValueMinMax(Number(initialValue) + 1000);
						return;
					}

					if (maxValue >= 149000) {
						updateValueMinMax(150000);
						return;
					}
					if (Number(initialValue) + 1000 > maxValue) {
						return;
					}
					updateValueMinMax(Number(initialValue) + 1000);

					break;
				}
				case 'subtract': {
					if (Number(initialValue) <= 5000) {
						return updateValueMinMax(Number(initialValue));
					} else {
						updateValueMinMax(Number(initialValue) - 1000);
					}
				}
			}
		};

		useClickOutside(warpperRef, () => {
			!!onBlur && onBlur(Number(initialValue));
		});

		return (
			<React.Fragment>
				<Styled.Wrapper ref={warpperRef}>
					<Styled.InputWrapper>
						<Styled.Button onClick={() => handleValue('subtract')}>
							<RiIcons.RiSubtractFill size={18} />
						</Styled.Button>

						<Styled.Input
							id={id}
							ref={ref}
							max={maxValue > 150000 ? 150000 : maxValue}
							config={currencyConfig}
							currency="BRL"
							value={Number(initialValue).toFixed()}
							onChange={handleInputChange}
						/>

						<Styled.Button onClick={() => handleValue('plus')}>
							<GoIcons.GoPlus size={18} />
						</Styled.Button>
					</Styled.InputWrapper>
					<Styled.MinMaxWrapper>
						<Styled.MinMaxInfo style={{ textAlign: 'left' }}>
							<span className="__text">
								Mínimo <br /> de R$ 5.000
							</span>
						</Styled.MinMaxInfo>
						<Styled.MinMaxInfo style={{ textAlign: 'right' }}>
							<span className="__text">
								Máximo <br />
								de{' '}
								{formatCurrency(Number(maxValue > 150000 ? 150000 : maxValue))}
							</span>
						</Styled.MinMaxInfo>
					</Styled.MinMaxWrapper>
				</Styled.Wrapper>
				{!!error && <Styled.Error>{error}</Styled.Error>}
			</React.Fragment>
		);
	}
);

InputMoneyVariant.displayName = 'InputMoneyVariant';

export default InputMoneyVariant;
