import data from './data';
import * as Styled from './styles';

const CreditCard = () => {
	return (
		<Styled.Card>
			{data.map((item, index) => (
				<Styled.Options key={index}>
					<Styled.ImageBlock img={item.img} />
					<Styled.TextCard>
						<strong>{item.textBold}</strong>
						<p>{item.textLight}</p>
					</Styled.TextCard>
				</Styled.Options>
			))}
		</Styled.Card>
	);
};

export default CreditCard;
