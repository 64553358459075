import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
	${({ theme }) => css`
		width: 100%;
		margin-top: ${theme.spacings.xsmall};
		padding: 2rem 1.6rem;

		display: inline-flex;
		align-items: center;
		justify-content: space-between;

		border: 3px solid ${theme.colors.gray};
		border-radius: 3px;
	`}
`;

export const TextContents = styled.div`
	${({ theme }) => css`
		font-family: ${theme.font.family.medium}, sans-serif;
		font-size: ${theme.font.sizes.xsmall};
		font-weight: 500;

		p.error {
			color: ${theme.colors.error};
		}
	`}
`;
