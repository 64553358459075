import * as Styled from './styles';
import { IconBaseProps } from 'react-icons';

type HeadingProps = {
	children: string;
	size?: 'normal' | 'large';
	headingColor?: 'primary' | 'secondary';
	icon?: IconBaseProps;
};

const Heading = ({
	children,
	size = 'normal',
	icon,
	headingColor = 'primary'
}: HeadingProps) => (
	<Styled.Heading headingColor={headingColor} size={size}>
		<div className="align">
			{!!icon && icon}
			{children}
		</div>
	</Styled.Heading>
);

export default Heading;
