import styled, { css } from 'styled-components';

export const EndCard = styled.div`
	width: 100%;
	margin-top: 2.5rem;
	padding: 1.8rem;

	border-radius: 2rem 2rem 0 0;
	background-color: #d9edf3;
`;

export const Header = styled.div`
	${({ theme }) => css`
		padding-bottom: 1.8rem;

		display: flex;
		align-items: center;
		justify-content: space-between;
		font-family: ${theme.font.family.regular};

		color: ${theme.colors.blue.weight};

		border-bottom: 1px solid #c3dde5;

		p {
			margin-right: 3rem;
		}

		button {
			width: 28px;
			height: 28px;
			padding: 0.8rem;

			display: flex;
			align-items: center;
			justify-content: center;

			background-color: ${theme.colors.blue.pacificBlue};
			color: ${theme.colors.white};

			border: 0;
			border-radius: 100%;
		}
	`}
`;

export const ContainerFooter = styled.div`
	${({ theme }) => css`
		width: 100%;
		padding: 1.6rem 0;

		display: inline-flex;
		align-items: center;
		flex-direction: column;

		border-bottom: 1px solid #c3dde5;

		color: ${theme.colors.blue.weight};

		a {
			margin-top: 1rem;
			padding: 0.7rem 3rem;
			text-decoration: none;

			color: ${theme.colors.blue.pacificBlue};
			background-color: transparent;

			border-radius: 2rem;
			border: 1px solid ${theme.colors.blue.pacificBlue};

			${theme.media.query('450')} {
				font-size: ${theme.font.sizes.small};
			}

			${theme.media.query(theme.breakpoints.md)} {
				font-size: ${theme.font.sizes.xsmall};
			}
		}

		& + p {
			margin-top: 1.6rem;
			text-align: center;
		}
	`}
`;

export const ContainerFooterSmall = styled(ContainerFooter)`
	${({ theme }) => css`
		font-size: ${theme.font.sizes.xsmall};
	`}
`;

export const ContainerFollow = styled.div`
	width: 100%;
	margin-top: 1rem;

	display: flex;
	justify-content: center;

	a:not(:last-child) {
		margin-right: 2.1rem;
	}
`;

export const DescriptionFooter = styled.div`
	${({ theme }) => css`
		width: 100%;
		margin-top: 2rem;

		display: flex;
		justify-content: space-around;

		p {
			padding: 0 0.4rem;
			font-family: ${theme.font.family.light};
			font-size: 0.9rem;
			text-align: center;
			line-height: 1.3rem;

			color: ${theme.colors.blue.weight};
		}

		p:nth-child(1) {
			border-right: 1px solid #c3dde5;
		}

		a {
			/* text-decoration: none; */
			color: ${theme.colors.blue.weight};
		}
	`}
`;
