import styled, { css } from 'styled-components';

export const Card = styled.div`
	${({ theme }) => css`
		width: 100%;
		margin-top: 2rem;
		padding: 2.8rem 2rem;

		background-color: ${theme.colors.blue.outlineBlue};

		border-radius: 1.5rem;
	`}
`;

export const Options = styled.div`
	${({ theme }) => css`
		width: 100%;
		padding: 2.5rem;

		display: flex;
		flex-direction: column;
		align-items: center;

		font-family: ${theme.font.family.light};
		text-align: center;

		background-color: ${theme.colors.white};
		color: ${theme.colors.blue.weight};

		border-radius: 2rem;

		> p {
			margin-top: -3rem;
			margin-bottom: 1rem;
		}

		> span {
			font-size: ${theme.font.sizes.xsmall};
			font-style: italic;
		}

		& + div {
			margin-top: 5rem;
		}
	`}
`;

export const Bubble = styled.div`
	${({ theme }) => css`
		width: 5.6rem;
		height: 5.6rem;
		margin: 0 auto;

		display: flex;
		align-items: center;
		justify-content: center;

		background-color: ${theme.colors.blue.outlineBlue};

		transform: translateY(-90%);

		border: 2px solid ${theme.colors.white};
		border-radius: 100%;

		img {
			width: 5rem;
		}
	`}
`;
