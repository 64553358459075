import styled, { css, DefaultTheme } from 'styled-components';

const modifiers = {
	error: (theme: DefaultTheme) => css`
		color: ${theme.colors.error};
	`,
	disabled: (theme: DefaultTheme) => css`
		color: ${theme.colors.blue.disabled};
	`
};

type StyledInputProps = {
	required?: boolean;
};

const InputModifiers = {
	required: (theme: DefaultTheme) => css`
		+ ${Label}::after {
			content: '*';
			color: ${theme.colors.blue.weight};
			font-family: ${theme.font.family.medium};
		}
	`
};

export const Container = styled.div`
	display: flex;
	flex-direction: column-reverse;
	width: 100%;
`;

export const Input = styled.input<StyledInputProps>`
	${({ theme, required }) => css`
		width: 100%;
		height: 3rem;
		padding: 0 1.2rem;
		font-size: ${theme.font.sizes.xsmall};
		font-family: ${theme.font.family.medium}, sans-serif;

		color: ${theme.colors.blue.weight};
		background-color: ${theme.colors.gray};

		border: 0;
		border-radius: 3px;

		&::-webkit-inner-spin-button {
			appearance: none;
		}

		${required && InputModifiers.required(theme)}
	`}
`;

export const Label = styled.label<{ disabled?: boolean }>`
	${({ theme, disabled }) => css`
		color: ${theme.colors.blue.weight};
		font-size: ${theme.font.sizes.xsmall};
		font-family: ${theme.font.family.regular}, sans-serif;

		${!!disabled && modifiers.disabled(theme)}
	`}
`;

export const HelperText = styled.p<{ error?: boolean; disabled?: boolean }>`
	${({ theme, error, disabled }) => css`
		margin-top: 0.5rem;

		color: ${theme.colors.blue.weight};

		${!!error && 'font-size: 1.15rem;'}
		${!!disabled && 'font-size: 1.15rem;'}
		font-family: ${theme.font.family.regular}, sans-serif;
		font-style: italic;

		${!!error && modifiers.error(theme)}
		${!!disabled && modifiers.disabled(theme)}
	`}
`;
