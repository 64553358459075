import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import history from 'service/history';

const apiAppdsv = axios.create({
	baseURL: process.env.REACT_APP_BASEURL_BACKEND_APPDSV
});

apiAppdsv.interceptors.request.use(
	// response => {
	// 	return response;
	// },
	(request: AxiosRequestConfig) => {
		const token = localStorage.getItem('token');
		apiAppdsv.defaults.headers.Authorization = `Bearer ${token}`;

		return request;
	},
	(error: AxiosError) => {
		if (error.response?.status === 401) {
			if (error.response?.data?.error === 'Unauthorized') {
				localStorage.removeItem('token');
				apiAppdsv.defaults.headers.Authorization = undefined;

				history.push({
					pathname: '/',
					search: location.search
				});
			}
		}
		return Promise.reject(error);
	}
);

export default apiAppdsv;
