import React, { createContext, useState, useEffect } from 'react';
import {
	ContextProps,
	ProviderProps,
	ResponseBrands,
	Brands,
	ResponseLocations,
	Locations,
	CarModels,
	ResponseCarYears,
	CarYears,
	ResponseCarInformations,
	CarInformation,
	InputValues,
	InputErrors
} from 'context/models/vehicule';
import financialServices from 'api/api-financial/services';
import locationsService from 'api/api-locations/services';
import shopkeeperServices from 'api/api-shopkeeper/services';
import { analyticsEvent } from 'service/analytics';
import { vehicleDataValidations } from 'utils/validations';
import history from 'service/history';
import useSteps from 'context/hooks/useSteps';
import alphabeticalSort from 'utils/ordered';
import useMoney from 'context/hooks/useMoney';
import OrderValueSort from 'utils/orderedValue';
import useAnalytics from 'context/hooks/useAnalytics';

export const VehiculeContext = createContext<ContextProps>({} as ContextProps);

const VehiculeProvider = ({ children }: ProviderProps) => {
	const { changeCompletedState } = useSteps();
	const { updateCarPrice } = useMoney();
	const [inputView, setInputView] = useState(true);
	const [isNotCarError, setIsNotCarError] = useState(true);
	const [licenseResponseError, setLicenseResponseError] = useState(false);
	const [inputsDisabled, setInputsDisabled] = useState(true);

	//loadings
	const [loadingLicence, setLoadingLicence] = useState(false);
	const [autocompleteLoading] = useState(false);

	//input values
	const [licensePlate, setLicensePlate] = useState('');
	const [fipePrice, setFipePrice] = useState(0);
	const [inputErrors, setInputErrors] = useState<InputErrors>(
		{} as InputErrors
	);
	const [inputValues, setInputValues] = useState<InputValues>(
		{} as InputValues
	);

	const [locations, setLocations] = useState<Locations[]>([]);
	const [brands, setBrands] = useState<Brands[]>([]);
	const [carModels, setCarModels] = useState<CarModels[]>([]);
	const [carYears, setCarYears] = useState<CarYears[]>([]);
	const [carInformations, setCarInformations] = useState<CarInformation>(
		{} as CarInformation
	);

	const clearVehiculeData = () => {
		setLicensePlate('');
		setFipePrice(0);
		setInputErrors({});
		setInputValues({} as InputValues);
		setCarYears([]);
		setCarInformations({} as CarInformation);
		setInputView(true);
		setInputsDisabled(true);
		setInputErrors({} as InputErrors);
	};

	const { analyticsValue, handleAddAnalyticsValue } = useAnalytics();

	const getBrands = async () => {
		try {
			const { data } = await financialServices.getBrands<ResponseBrands>();

			const newObjectBrands = data.map(brand => ({
				id: brand.ordem,
				name: brand.marca,
				shortBrand: brand.siglaMarca
			}));

			const ordered = alphabeticalSort(newObjectBrands);

			setBrands(ordered);
		} catch (err) {
			console.error('getBrands -> ', err);
		}
	};

	const verifyBrandExists = (brand: string) => {
		if (carInformations.brand) return false;

		const brandExists = brands.find(item => item.name === brand);

		if (!brandExists) {
			return true;
		}

		return false;
	};

	const yearCarInfoValidation = () => {
		if (carInformations.yearModel) return false;

		return true;
	};

	const getLocations = async () => {
		const locations = await locationsService.getLocations<
			ResponseLocations[]
		>();

		const newObjectLocation = locations.map(location => ({
			id: location.id,
			sigla: location.sigla,
			name: location.nome
		}));

		const orderedLocations = alphabeticalSort(newObjectLocation);
		const orderedLocationsUpdated = mapToLocationOrder(orderedLocations);
		const orderedLocationsOrder = OrderValueSort(orderedLocationsUpdated);
		setLocations(orderedLocationsOrder);
	};

	const mapToLocationOrder = (array: any[]) => {
		return array.map((location, index) => ({
			id: location.id,
			order: location.sigla !== 'RS' ? index + 1 : 0,
			sigla: location.sigla,
			name: location.name
		}));
	};

	const getCarYears = async (carModel: string) => {
		const fipeCode = carModels.find(item => item.name === carModel)?.id;

		if (fipeCode) {
			const response = await financialServices.getCarYears<ResponseCarYears>(
				fipeCode
			);

			const newObjectYears = response.data.map(year => ({
				id: year.codigoFipe,
				name: String(year.codAno),
				price: year.preco
			}));

			const ordered = alphabeticalSort(newObjectYears);

			setCarYears(ordered);
		}
	};

	const defineModelYear = (yearFabrication: string) => {
		const year = carYears.find(item => item.name === yearFabrication);

		if (year?.name) {
			updateCarPrice(year.price!);
			setFipePrice(year.price!);
		}
	};

	const getLicensePlate = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		if (licensePlate) {
			setLoadingLicence(true);
			setInputView(false);
			try {
				setFieldsValue('paidoff', true);
				setIsNotCarError(false);

				const response =
					await shopkeeperServices.getLicensePlate<ResponseCarInformations>(
						licensePlate?.replaceAll('-', '')
					);

				setInputsDisabled(false);

				if (response.data.precoOriginal === 0) throw Error('Carro sem preço');
				if (response.data.nomeEspecie.toUpperCase() !== 'AUTOMÓVEL')
					throw Error('IsNotCar');

				updateCarPrice(response.data.precoOriginal);

				const newObjectCarInformations: CarInformation = {
					brand: response.data.marca,
					model: response.data.fipeModelo,
					yearModel: response.data.anoModelo,
					originalPrice: response.data.precoOriginal,
					fipeCode: response.data.fipe,
					licensePlate: response.data.placa,
					yearManufacture: response.data.anoFabricacao
				};

				const analyticsBody = {
					...analyticsValue,
					placa: licensePlate,
					marca: response.data.marca,
					modelo: response.data.fipeModelo,
					anoModelo: response.data.anoModelo,
					precoFipe: response.data.precoOriginal
				};

				analyticsEvent('ck_consultar_placa', analyticsBody);

				handleAddAnalyticsValue(analyticsBody);

				setCarInformations(newObjectCarInformations);
				setLoadingLicence(false);
				setInputView(false);
				setInputErrors({} as InputErrors);
			} catch (err) {
				setInputView(false);
				setLoadingLicence(false);
				if (err instanceof Error) {
					setIsNotCarError(err.message === 'IsNotCar');
				} else {
					setIsNotCarError(false);
				}
				setLicenseResponseError(true);
				setCarInformations({} as CarInformation);
			}
		}
	};

	const setFieldsValue = (
		fieldName: string,
		value: string | boolean | number
	) => {
		if (fieldName === 'license-plate') setLicensePlate(String(value));
		setInputValues(oldState => ({ ...oldState, [fieldName]: value }));
	};

	const removeInputErrors = (inputName: string) => {
		if (inputName in inputErrors) {
			const newInputErrors = Object.assign({}, inputErrors);
			delete newInputErrors[inputName];
			setInputErrors(newInputErrors);
		}
	};

	const clearFields = (fieldName: string) => {
		switch (fieldName) {
			case 'license-plate': {
				setLicensePlate('');
				setInputValues(old => ({ ...old, 'license-plate': '', location: '' }));
				setCarInformations({} as CarInformation);
				setInputsDisabled(true);

				if (!Object.values(carInformations).length) {
					setInputView(false);
				}
				setInputView(true);
				setIsNotCarError(true);
				setLicenseResponseError(false);
				break;
			}
			default:
		}
	};

	const handleInputsDisabled = () => {
		analyticsEvent('ck_nao_lembro_placa', analyticsValue);
		setInputsDisabled(false);
		setIsNotCarError(false);
		setLicenseResponseError(false);
		setInputView(true);
		setLicensePlate('');
		setInputValues({} as InputValues);
		setCarInformations({} as CarInformation);
		setLoadingLicence(false);
	};

	const handleFoward = async () => {
		setInputErrors({});
		if (!inputsDisabled) {
			const errors = await vehicleDataValidations(inputValues);

			const brandExits = verifyBrandExists(inputValues.brand);
			const validValidationBrand = !carInformations.brand ? brandExits : false;

			if (!!carInformations.yearModel && !!errors?.modelYear) {
				delete errors.modelYear;
			}

			if (!!Object.keys(errors || {}).length || validValidationBrand) {
				if (validValidationBrand) {
					setInputErrors(oldErrors => ({
						...oldErrors,
						brand: 'Marca inválida'
					}));
				}

				setInputErrors(oldErrors => ({
					...oldErrors,
					...errors
				}));

				return;
			}

			const analyticsBodyConsult = {
				...analyticsValue,
				localidade: inputValues.location,
				quitacao: inputValues.paidoff
			};

			const analyticsBody = {
				...analyticsValue,
				marca: inputValues.brand,
				modelo: inputValues.model,
				anoModelo: inputValues.modelYear,
				localidade: inputValues.location,
				quitacao: inputValues.paidoff
			};

			if (Object.keys(carInformations).length !== 0) {
				analyticsEvent('ck_avancar_segunda_etapa', analyticsBodyConsult);

				handleAddAnalyticsValue(analyticsBodyConsult);
			} else {
				analyticsEvent('ck_avancar_segunda_etapa', analyticsBody);

				handleAddAnalyticsValue(analyticsBody);
			}
			changeCompletedState(2, true);
			setInputErrors({});
			history.push({
				pathname: '/switch-condition',
				search: location.search
			});
		}
	};

	const value: ContextProps = {
		locations,
		brands,
		carModels,
		carYears,
		getCarYears,
		clearVehiculeData,
		loadingLicence,
		defineModelYear,
		carInformations,
		getLicensePlate,
		clearFields,
		setFieldsValue,
		removeInputErrors,
		licensePlate,
		inputView,
		autocompleteLoading,
		isNotCarError,
		licenseResponseError,
		handleInputsDisabled,
		inputsDisabled,
		setInputsDisabled,
		checked: inputValues.paidoff,
		inputValues,
		handleFoward,
		inputErrors,
		fipePrice
	};

	useEffect(() => {
		getBrands();
		getLocations();
		setInputsDisabled(true);
	}, []);

	useEffect(() => {
		if (carInformations) {
			updateCarPrice(carInformations.originalPrice);
			setInputValues(oldState => ({
				...oldState,
				brand: carInformations.brand,
				model: carInformations.model,
				modelYear: carInformations.yearModel
			}));
		}
	}, [carInformations, updateCarPrice]);

	return (
		<VehiculeContext.Provider value={value}>
			{children}
		</VehiculeContext.Provider>
	);
};

export default VehiculeProvider;
